export const formatDate = (date: string) => {
  const newDate = new Date(date);
  newDate.setTime(newDate.getTime());

  return newDate.toLocaleDateString("DE-de");
};

export const formatDateAndTime = (date: string) => {
  const newDate = new Date(date);
  newDate.setTime(newDate.getTime());

  return newDate.toLocaleString("DE-de");
};
