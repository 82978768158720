import { toast } from "react-toastify";
import { IArea, useStore } from "../store";
import { LogicValidationError, ValidationError } from "./api";
import { useApi } from "./api-factory";

export interface ICreateArea {
  name: string;
  polygon: any[];
  promoters: { id: string }[];
  zipCode: string;
  city: string;
}

export interface IUpdateArea {
  name?: string;
  promoters?: { id: string }[];
  zipCode?: string;
  city?: string;
  comment?: string;
  polygon?: any[];
  completedAt?: string;
  isBlocked?: boolean;
  noOfHouses?: number;
}

export const useAreaActions = () => {
  const [, setStore] = useStore();
  const { api } = useApi();

  const getAllAreas = async () => {
    try {
      setStore((old) => ({
        ...old,
        api: { reading: true },
      }));
      const areas = await api.get("/area");
      if (areas) {
        calculateStatus(areas);
        areas.sort(
          (a: IArea, b: IArea) =>
            new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
        );

        const completedAreas = areas.filter(
          (area: IArea) => area.status === "completed"
        );

        const activeAreas = areas.filter(
          (area: IArea) => area.status === "active"
        );

        const notAssignedAreas = areas.filter(
          (area: IArea) => area.status === "notAssigned"
        );

        const blockedAreas = areas.filter(
          (area: IArea) => area.status === "blocked"
        );
        setStore((old) => ({
          ...old,
          areas: [
            ...activeAreas,
            ...notAssignedAreas,
            ...completedAreas,
            ...blockedAreas,
          ],
          api: { reading: false },
        }));
      }
    } catch (e) {
      if (e instanceof ValidationError || e instanceof LogicValidationError) {
        throw e;
      } else {
        console.error(e);
        toast("Ein unerwarteter Fehler ist aufgetreten.", {
          type: "error",
          position: "top-right",
        });
      }
    }
  };

  const blockArea = async (areaId: string, isBlocked: boolean = true) => {
    try {
      await updateAreaInternal(areaId, {
        isBlocked: isBlocked,
      });
      toast(
        "Das Gebiet wurde erfolgreich " +
          (isBlocked ? "gesperrt" : "entsperrt") +
          ".",
        {
          type: "success",
          position: "top-right",
        }
      );
    } catch (e) {
      if (e instanceof ValidationError || e instanceof LogicValidationError) {
        throw e;
      } else {
        console.error(e);
        toast("Ein unerwarteter Fehler ist aufgetreten.", {
          type: "error",
          position: "top-right",
        });
      }
    }
  };

  const completeArea = async (areaId: string) => {
    try {
      await updateAreaInternal(areaId, {
        completedAt: new Date().toISOString(),
        promoters: [],
      });
      toast("Das Gebiet wurde erfolgreich abgeschlossen.", {
        type: "success",
        position: "top-right",
      });
    } catch (e) {
      if (e instanceof ValidationError || e instanceof LogicValidationError) {
        throw e;
      } else {
        console.error(e);
        toast("Ein unerwarteter Fehler ist aufgetreten.", {
          type: "error",
          position: "top-right",
        });
      }
    }
  };

  const calculateStatus = (areas: IArea[]) => {
    for (let area of areas) {
      const now = new Date();
      const completedAt = new Date(area.completedAt);
      if (area.isBlocked) {
        area.status = "blocked";
        // Time used is 6 months for now
      } else if (completedAt.getTime() + 1000 * 15778476 > now.getTime()) {
        area.status = "completed";
      } else if (area.promoters.length === 0) {
        area.status = "notAssigned";
      } else {
        area.status = "active";
      }
    }
  };

  const createArea = async (
    createAreaDto: ICreateArea
  ): Promise<IArea | undefined> => {
    try {
      setStore((old) => ({
        ...old,
        api: { reading: true },
      }));
      const area = await api.post("/area", createAreaDto);
      await getAllAreas();
      setStore((old) => ({
        ...old,
        api: { reading: false },
      }));
      return area;
    } catch (e) {
      if (e instanceof ValidationError || e instanceof LogicValidationError) {
        throw e;
      } else {
        console.error(e);
        toast("Ein unerwarteter Fehler ist aufgetreten.", {
          type: "error",
          position: "top-right",
        });
      }
    }
  };

  const updateArea = async (areaId: string, updateAreaDto: IUpdateArea) => {
    try {
      setStore((old) => ({
        ...old,
        api: { reading: true },
      }));
      await api.post("/area/" + areaId, updateAreaDto);
      await getAllAreas();
      setStore((old) => ({
        ...old,
        api: { reading: false },
      }));
      toast("Das Gebiet wurde erfolgreich geändert.", {
        type: "success",
        position: "top-right",
      });
    } catch (e) {
      if (e instanceof ValidationError || e instanceof LogicValidationError) {
        throw e;
      } else {
        console.error(e);
        toast("Ein unerwarteter Fehler ist aufgetreten.", {
          type: "error",
          position: "top-right",
        });
      }
    }
  };

  const updateAreaInternal = async (
    areaId: string,
    updateAreaDto: IUpdateArea
  ) => {
    setStore((old) => ({
      ...old,
      api: { reading: true },
    }));
    await api.post("/area/" + areaId, updateAreaDto);
    await getAllAreas();
    setStore((old) => ({
      ...old,
      api: { reading: false },
    }));
  };

  const deleteArea = async (areaId: string) => {
    try {
      setStore((old) => ({
        ...old,
        api: { reading: true },
      }));
      await api.remove("/area/" + areaId);
      await getAllAreas();
      setStore((old) => ({
        ...old,
        api: { reading: false },
      }));
      toast("Das Gebiet wurde erfolgreich gelöscht.", {
        type: "success",
        position: "top-right",
      });
    } catch (e) {
      if (e instanceof ValidationError || e instanceof LogicValidationError) {
        throw e;
      } else {
        console.error(e);
        toast("Ein unerwarteter Fehler ist aufgetreten.", {
          type: "error",
          position: "top-right",
        });
      }
    }
  };

  return {
    getAllAreas,
    updateArea,
    deleteArea,
    createArea,
    blockArea,
    completeArea,
  };
};
