import { FC } from "react";
import { IArea, useStore } from "../../store";
import { useAreaActions } from "../../actions/areaActions";
import { Card, Col, Dropdown, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckSquare,
  faEye,
  faLocationCrosshairs,
  faLock,
  faLockOpen,
  faPenToSquare,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { AreaStatusBadge } from "./AreaStatusBadge";
import { sortMissionsByDate } from "../../utils/sort";
import { formatDateAndTime } from "../../utils/formatDate";
import Subline from "../../shared/ui/Subline";
import ThreeDotToggle from "../../shared/ui/ThreeDotToggle";
import { useNavigate } from "react-router-dom";
import Button from "../../shared/ui/Button";
import { ListRowElement } from "../../shared/ui/List";
import { SmallList } from "../../shared/ui/SmallList";
import { Element } from "react-scroll";
import { isAdmin } from "../../utils/isRole";

interface AreaCardProps {
  area: IArea;
  isActive: boolean;
  onEdit: () => void;
  onView: () => void;
  onFinish: () => void;
  onNewMission: () => void;
}

const AreaCard: FC<AreaCardProps> = (props: AreaCardProps) => {
  const { blockArea, completeArea } = useAreaActions();
  const navigate = useNavigate();

  const [store] = useStore();

  const lastMissionDate =
    props.area.missions.sort(sortMissionsByDate)[0]?.createdAt;

  const navigateToArea = (id: string) => {
    navigate("/areas/" + id);
  };

  const listElements: ListRowElement[] = [
    {
      name: "Aktive Promoter",
      value:
        props.area.promoters && props.area.promoters.length !== 0
          ? props.area.promoters
              .map((promoter) => promoter.firstname + " " + promoter.lastname)
              .join(", ")
          : "/",
    },
    {
      name: "Anzahl Häuser",
      value: props.area.noOfHouses ?? "/",
    },
    {
      name: "Letzter Einsatz",
      value: lastMissionDate
        ? formatDateAndTime(lastMissionDate)
        : "Bisher kein Einsatz",
    },
    {
      name: "Zuletzt abgeschlossen",
      value: props.area.completedAt
        ? formatDateAndTime(props.area.completedAt)
        : "/",
    },
  ];

  const className = props.isActive
    ? "border border-primary border-2 mb-4 p-4"
    : "mb-4 p-4";

  return (
    <Element name={props.area.id} id={props.area.id}>
      <Card className={className}>
        <Row>
          <Col>
            <AreaStatusBadge status={props.area.status}></AreaStatusBadge>
          </Col>
          <Col className="col-auto">
            <Dropdown drop="down">
              <Dropdown.Toggle as={ThreeDotToggle}></Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  as="button"
                  onClick={() => navigateToArea(props.area.id)}
                >
                  <FontAwesomeIcon style={{ marginRight: 10 }} icon={faEye} />
                  Anzeigen
                </Dropdown.Item>
                <Dropdown.Item as="button" onClick={props.onEdit}>
                  <FontAwesomeIcon
                    style={{ marginRight: 10 }}
                    icon={faPenToSquare}
                  />
                  Bearbeiten
                </Dropdown.Item>
                <Dropdown.Divider />
                {props.area.status !== "completed" &&
                  props.area.status !== "blocked" && (
                    <Dropdown.Item
                      as="button"
                      onClick={() => completeArea(props.area.id)}
                    >
                      <FontAwesomeIcon
                        style={{ marginRight: 10 }}
                        icon={faCheckSquare}
                      />
                      Abschließen
                    </Dropdown.Item>
                  )}

                <Dropdown.Item
                  as="button"
                  onClick={() =>
                    blockArea(
                      props.area.id,
                      props.area.isBlocked ? false : true
                    )
                  }
                >
                  <FontAwesomeIcon
                    style={{ marginRight: 10 }}
                    icon={props.area.isBlocked ? faLockOpen : faLock}
                  />
                  {props.area.isBlocked ? "Entsperren" : "Sperren"}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>
        <Row>
          <Col>
            <h3 className="fw-bold">{props.area.name}</h3>
            <Subline>
              {props.area.city + " (" + props.area.zipCode + ")"}
            </Subline>
          </Col>
        </Row>
        <Row className="mb-4">
          <Col>
            <SmallList data={listElements}></SmallList>
          </Col>
        </Row>
        <Row>
          <Col>
            <Button onClick={props.onView} style={{ marginRight: 10 }}>
              <FontAwesomeIcon icon={faLocationCrosshairs} />
            </Button>
            {(props.area.status === "active" ||
              isAdmin(store.accessTokenPayload)) && (
              <Button onClick={props.onNewMission} style={{}}>
                <FontAwesomeIcon icon={faPlus} style={{ marginRight: 10 }} />
                Einsatz erstellen
              </Button>
            )}
          </Col>
        </Row>
      </Card>
    </Element>
  );
};

export default AreaCard;
