import { faClose, faFile } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC, useEffect } from "react";
import { Form, ListGroup, ListGroupItem } from "react-bootstrap";
import Dropzone from "react-dropzone";
import { useFormContext } from "react-hook-form";
import { IDocument } from "../../store";
import Button from "../ui/Button";
import { ErrorTypes } from "./ErrorTypes";

interface FileInputFieldProps {
  registerName: string;
  registerNameDeletedFiles?: string;
  label: string;
  type?: string;
  required?: boolean;
  errorMessages?: ErrorTypes;
  alreadyUploadedFiles?: IDocument[];
}

const FileInputField: FC<FileInputFieldProps> = (
  props: FileInputFieldProps
) => {
  const {
    register,
    setValue,
    getValues,
    unregister,
    formState: { errors },
  } = useFormContext();

  useEffect(() => {
    register(props.registerName);
    if (props.registerNameDeletedFiles) {
      register(props.registerNameDeletedFiles);
    }
    return () => {
      unregister(props.registerName);
      if (props.registerNameDeletedFiles) {
        unregister(props.registerNameDeletedFiles);
      }
    };
  }, []);

  return (
    <Form.Group className="mb-3">
      <Form.Label className="fw-bold">
        <h5>{props.label + (props.required ? " *" : "")}</h5>
      </Form.Label>
      <Dropzone
        noClick
        multiple
        onDrop={(acceptedFiles: File[]) => {
          const oldValues = getValues(props.registerName);
          const files = [];
          if (oldValues) {
            files.push(...oldValues);
          }
          files.push(...acceptedFiles);
          setValue(props.registerName, files, {
            shouldValidate: true,
          });
        }}
      >
        {({ getRootProps, getInputProps, open, isDragActive }) => {
          const removeExistingFile = (file: IDocument) => {
            console.log("Hi");
            if (props.registerNameDeletedFiles) {
              const deletedDocuments = getValues(
                props.registerNameDeletedFiles
              );
              const newDocuments = [];
              if (deletedDocuments) {
                newDocuments.push(...deletedDocuments);
              }
              newDocuments.push(file.id);
              setValue(props.registerNameDeletedFiles, newDocuments, {
                shouldValidate: true,
              });
            }
          };

          const removeFile = (file: File) => {
            const files = getValues(props.registerName);
            const filesWithoutFileToBeRemoved = files.filter(
              (filterFile: File) => filterFile !== file
            );
            setValue(props.registerName, filesWithoutFileToBeRemoved, {
              shouldValidate: true,
            });
          };

          return (
            <div>
              <div
                style={{
                  borderStyle: "dashed",
                  borderColor: "#C1C1C1",
                  borderWidth: "2px",
                  borderRadius: "5px",
                  backgroundColor: isDragActive ? `#C1C1C1` : "transparent",
                  padding: "20px",
                }}
                {...getRootProps()}
              >
                <input {...getInputProps()} />
                <Button
                  onClick={open}
                  type="button"
                  style={{ marginRight: 10 }}
                >
                  Dateien auswählen
                </Button>
                oder per Drag & Drop hinzufügen
              </div>

              <ListGroup style={{ marginTop: 20 }}>
                {getValues(props.registerName)?.map((file: File) => (
                  <ListGroupItem
                    active={false}
                    key={file.name + file.lastModified}
                    className="d-flex justify-content-between align-items-middle"
                  >
                    <div className="d-flex w-100 justify-content-between">
                      <span className="mb-1 d-flex align-items-center">
                        <FontAwesomeIcon
                          icon={faFile}
                          style={{ marginRight: 10 }}
                        />
                        {file.name}
                      </span>
                      <Button
                        type="button"
                        variant="light"
                        onClick={() => removeFile(file)}
                      >
                        <FontAwesomeIcon icon={faClose} />
                      </Button>
                    </div>
                  </ListGroupItem>
                ))}
                {props.alreadyUploadedFiles &&
                  props.registerNameDeletedFiles &&
                  props.alreadyUploadedFiles.length > 0 && <hr />}
                {props.alreadyUploadedFiles
                  ?.filter(
                    (document: IDocument) =>
                      !getValues(props.registerNameDeletedFiles!)?.includes(
                        document.id
                      )
                  )
                  .map((file) => (
                    <ListGroupItem
                      active={false}
                      key={file.id}
                      className="d-flex justify-content-between align-items-middle"
                    >
                      <div className="d-flex w-100 justify-content-between">
                        <span className="mb-1 d-flex align-items-center">
                          <FontAwesomeIcon
                            icon={faFile}
                            style={{ marginRight: 10 }}
                          />
                          {file.name}
                        </span>
                        <Button
                          type="button"
                          variant="light"
                          onClick={() => removeExistingFile(file)}
                        >
                          <FontAwesomeIcon icon={faClose} />
                        </Button>
                      </div>
                    </ListGroupItem>
                  ))}
              </ListGroup>
            </div>
          );
        }}
      </Dropzone>
    </Form.Group>
  );
};

export default FileInputField;
