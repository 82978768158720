import { faPlus, faPlusSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useOrganizationActions } from "../../actions/organizationActions";
import EmployeesTable from "../components/EmployeesTable";
import Button from "../../shared/ui/Button";
import Card from "../../shared/ui/Card";
import Headline from "../../shared/ui/Headline";
import { useStore } from "../../store";
import FullContainer from "../../shared/ui/FullContainer";

const Employees: FC = () => {
  const [store, setStore] = useStore();
  const { getUsersOrganization } = useOrganizationActions();
  const navigate = useNavigate();

  useEffect(() => {
    getUsersOrganization();
  }, []);

  if (!store.organization) {
    return <FullContainer>Laden...</FullContainer>;
  }

  return (
    <FullContainer>
      <Card>
        <Row>
          <Col>
            <Headline>Mitarbeiter</Headline>
          </Col>
          <Col className="col-auto">
            <Button onClick={() => navigate("/employees/create")}>
              <FontAwesomeIcon icon={faPlus} style={{ marginRight: 10 }} />{" "}
              Mitarbeiter erstellen
            </Button>
          </Col>
        </Row>

        <EmployeesTable data={store.organization.employees} />
      </Card>
    </FullContainer>
  );
};

export default Employees;
