import { toast } from "react-toastify";
import { IMission, useStore } from "../store";
import { useApi } from "./api-factory";
import { useAreaActions } from "./areaActions";

export interface ICreateMission {
  comment?: string;
  area: string;
  promoters: { id: string }[];
  salesFailed: number;
  salesSuccess: number;
  salesNotAvailable: number;
}

export interface IUpdateMission {
  comment?: string;
  area?: string;
  finisher?: { id: string };
  salesFailed?: number;
  salesSuccess?: number;
  salesNotAvailable?: number;
  salesStart?: string;
  salesEnd?: string;
  rating?: number;
  drivingTime?: number;
}

export interface ICreateDocument {
  mission: string;
  filename: string;
  fileExtension: string;
  fileDataUri: string;
}

export const useMissionActions = () => {
  const [, setStore] = useStore();
  const { api } = useApi();
  const { getAllAreas } = useAreaActions();

  const createMission = async (
    createMissionDto: ICreateMission,
    files?: FileList
  ): Promise<IMission> => {
    setStore((old) => ({
      ...old,
      api: { reading: true },
    }));
    const mission = await api.post("/mission", createMissionDto);
    if (files) {
      const fileList = await fileListToBase64(files);
      for (let i = 0; i < files.length; i++) {
        const filename = files[i].name;
        const fileExtension = filename.split(".").pop() || "";
        const fileDataUri = fileList[i];
        const document = {
          mission: mission.id,
          filename,
          fileExtension,
          fileDataUri,
        };
        await createDocument(document);
      }
    }
    setStore((old) => ({
      ...old,
      api: { reading: false },
    }));
    toast("Der Einsatz wurde erfolgreich erstellt.", {
      type: "success",
      position: "top-right",
    });
    return mission;
  };

  const fileListToBase64 = async (fileList: FileList): Promise<string[]> => {
    function getBase64(file: File): Promise<string> {
      const reader = new FileReader();
      return new Promise((resolve) => {
        reader.onload = (ev: any) => {
          resolve(ev.target.result);
        };
        reader.readAsDataURL(file);
      });
    }
    const promises = [];
    for (let i = 0; i < fileList.length; i++) {
      promises.push(getBase64(fileList[i]));
    }
    return await Promise.all(promises);
  };

  const updateMission = async (
    missionId: string,
    updateMissionDto: IUpdateMission,
    newFiles?: FileList,
    deleteDocuments?: string[]
  ) => {
    setStore((old) => ({
      ...old,
      api: { reading: true },
    }));
    await api.post("/mission/" + missionId, updateMissionDto);
    if (newFiles) {
      const fileList = await fileListToBase64(newFiles);
      for (let i = 0; i < newFiles.length; i++) {
        const filename = newFiles[i].name;
        const fileExtension = filename.split(".").pop() || "";
        const fileDataUri = fileList[i];
        const document = {
          mission: missionId,
          filename,
          fileExtension,
          fileDataUri,
        };
        await createDocument(document);
      }
    }
    if (deleteDocuments) {
      for (let deleteDocumentId of deleteDocuments) {
        await deleteDocument(deleteDocumentId);
      }
    }
    setStore((old) => ({
      ...old,
      api: { reading: false },
    }));
    toast("Der Einsatz wurde erfolgreich geändert.", {
      type: "success",
      position: "top-right",
    });
  };

  const createDocument = async (createDocumentDto: ICreateDocument) => {
    setStore((old) => ({
      ...old,
      api: { reading: true },
    }));
    await api.post("/document", createDocumentDto);
    setStore((old) => ({
      ...old,
      api: { reading: false },
    }));
  };

  const deleteDocument = async (documentId: string) => {
    setStore((old) => ({
      ...old,
      api: { reading: true },
    }));
    await api.remove("/document/" + documentId);
    setStore((old) => ({
      ...old,
      api: { reading: false },
    }));
  };

  const deleteMission = async (missionId: string) => {
    setStore((old) => ({
      ...old,
      api: { reading: true },
    }));
    await api.remove("/mission/" + missionId);
    await getAllAreas();
    setStore((old) => ({
      ...old,
      api: { reading: false },
    }));
    toast("Der Einsatz wurde erfolgreich gelöscht.", {
      type: "success",
      position: "top-right",
    });
  };

  return { createMission, updateMission, createDocument, deleteMission };
};
