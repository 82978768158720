import { FC, useEffect } from "react";
import { Form } from "react-bootstrap";
import { Controller, useFormContext } from "react-hook-form";
import { ErrorTypes } from "./ErrorTypes";
import EmojiRatingC from "../ui/EmojiRating";

interface EmojiRatingProps {
  registerName: string;
  label: string;
  type?: string;
  errorMessages?: ErrorTypes;
}

const EmojiRating: FC<EmojiRatingProps> = (props: EmojiRatingProps) => {
  const {
    register,
    unregister,
    control,
    setValue,
    clearErrors,
    formState: { errors },
  } = useFormContext();

  useEffect(() => {
    register(props.registerName);
    return () => {
      unregister(props.registerName);
    };
  }, []);

  return (
    <Form.Group className="mb-3">
      <Form.Label className="fw-bold">
        <h5>{props.label + " *"}</h5>
      </Form.Label>
      <Controller
        control={control}
        name={props.registerName}
        rules={{ required: "Bitte wählen Sie einen Emoji aus." }}
        render={({ field: { onChange, onBlur, value }, fieldState }) => (
          <EmojiRatingC
            rating={value}
            onChange={(rating) => {
              setValue(props.registerName, rating);
              clearErrors(props.registerName);
            }}
            error={errors[props.registerName] ? true : false}
          ></EmojiRatingC>
        )}
      />
      {errors[props.registerName] && (
        <div className="text-danger">
          <small>{String(errors[props.registerName]?.message)}</small>
        </div>
      )}
    </Form.Group>
  );
};

export default EmojiRating;
