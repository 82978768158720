import { FC, ReactNode } from "react";

interface BigNumberProps {
  children: ReactNode;
}

const BigNumber: FC<BigNumberProps> = (props: BigNumberProps) => {
  return <h1 className="fw-bold">{props.children}</h1>;
};

export default BigNumber;
