import { FC, useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";
import { useAreaActions } from "../../actions/areaActions";
import { useOrganizationActions } from "../../actions/organizationActions";
import { IArea, IUser, useStore } from "../../store";
import InputField from "../../shared/form/InputField";
import MultiSelect from "../../shared/form/MultiSelect";
import Textarea from "../../shared/form/Textarea";
import Button from "../../shared/ui/Button";

interface AreaEditModalProps {
  open: boolean;
  area?: IArea;
  onSuccess: () => void;
  onAbort: () => void;
}

type UpdateAreaForm = {
  name: string;
  promoters: string[];
  city: string;
  zipCode: string;
  comment?: string;
  noOfHouses?: number;
};

const AreaEditModal: FC<AreaEditModalProps> = (props: AreaEditModalProps) => {
  const [store, setStore] = useStore();
  const [loading, setLoading] = useState<boolean>(false);
  const { getUsersOrganization } = useOrganizationActions();
  const methods = useForm<UpdateAreaForm>({
    defaultValues: {
      name: props.area?.name,
      city: props.area?.city,
      zipCode: props.area?.zipCode,
      noOfHouses: props.area?.noOfHouses,
      promoters: props.area?.promoters.map((promoter) => promoter.id),
      comment: props.area?.comment,
    },
  });
  const { updateArea } = useAreaActions();
  const onSubmit = async (data: UpdateAreaForm) => {
    const areaId = props.area?.id;
    setLoading(true);
    if (areaId) {
      await updateArea(areaId, {
        name: data.name,
        city: data.city,
        zipCode: data.zipCode,
        noOfHouses: data.noOfHouses,
        promoters: data.promoters.flatMap((promoter) => ({
          id: promoter,
        })),
        polygon: props.area?.polygon,
      });
    }
    props.onSuccess();
    setLoading(false);
  };

  useEffect(() => {
    setLoading(false);
    methods.reset({
      name: props.area?.name,
      zipCode: props.area?.zipCode,
      city: props.area?.city,
      noOfHouses: props.area?.noOfHouses,
      comment: props.area?.comment,
      promoters: props.area?.promoters.map((promoter) => promoter.id),
    });
  }, [methods.reset, props.area]);

  useEffect(() => {
    getUsersOrganization();
  }, []);

  const employees = store.organization?.employees;

  return (
    <Modal
      show={props.open}
      backdrop="static"
      keyboard={false}
      onHide={props.onAbort}
    >
      <Modal.Header closeButton>
        <Modal.Title>Gebiet bearbeiten</Modal.Title>
      </Modal.Header>
      <FormProvider {...methods}>
        <Form onSubmit={methods.handleSubmit(onSubmit)}>
          <Modal.Body>
            <InputField registerName="name" label="Name" required />
            <InputField registerName="city" label="Ort" required />
            <InputField registerName="zipCode" label="Postleitzahl" required />
            <InputField
              registerName="noOfHouses"
              type="number"
              label="Anzahl Häuser"
            />
            <MultiSelect registerName="promoters" label="Aktive Promoter">
              {employees?.map((employee: IUser) => (
                <option value={employee.id} key={employee.id}>
                  {employee.firstname} {employee.lastname}
                </option>
              ))}
            </MultiSelect>
            <Textarea registerName="comment" label="Kommentar"></Textarea>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={props.onAbort}>
              Abbrechen
            </Button>
            <Button variant="primary" type="submit" loading={loading}>
              Speichern
            </Button>
          </Modal.Footer>
        </Form>
      </FormProvider>
    </Modal>
  );
};

export default AreaEditModal;
