import { FC, ReactNode } from "react";

interface HeadlineProps {
  children: ReactNode;
}

const Headline: FC<HeadlineProps> = (props: HeadlineProps) => {
  return <h3 className="fw-bold mb-4">{props.children}</h3>;
};

export default Headline;
