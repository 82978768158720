import { faEllipsis } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { forwardRef, LegacyRef, MouseEventHandler, ReactNode } from "react";

const ThreeDotToggle = forwardRef(
  (
    { children, onClick }: { children: ReactNode; onClick: MouseEventHandler },
    ref: LegacyRef<HTMLAnchorElement>
  ) => (
    <a
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      className="link-secondary link-dots cursor-hover"
    >
      <FontAwesomeIcon size="xl" icon={faEllipsis}></FontAwesomeIcon>
    </a>
  )
);

export default ThreeDotToggle;
