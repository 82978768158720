import { toast } from "react-toastify";
import { useStore } from "../store";
import { LogicValidationError, ValidationError } from "./api";
import { useApi } from "./api-factory";
import { useOrganizationActions } from "./organizationActions";

export interface ICreateEmployee {
  username: string;
  password: string;
  firstname: string;
  lastname: string;
  role: string;
  alternativeId?: string;
  street?: string;
  houseNumber?: string;
  city?: string;
  zipCode?: string;
  country?: string;
  birthday?: string;
  telephone?: string;
  email?: string;
  iban?: string;
  bic?: string;
  additionalAgreement?: string;
  hourlyRate?: number;
  commission?: number;
  paymentCurrency?: string;
  enteredAt?: string;
  leftAt?: string;
}

export interface IUpdateEmployee {
  firstname?: string;
  lastname?: string;
  role?: string;
  alternativeId?: string;
  street?: string;
  houseNumber?: string;
  city?: string;
  zipCode?: string;
  country?: string;
  birthday?: string;
  telephone?: string;
  email?: string;
  iban?: string;
  bic?: string;
  additionalAgreement?: string;
  hourlyRate?: number;
  commission?: number;
  paymentCurrency?: string;
  enteredAt?: string;
  leftAt?: string;
}

export const useEmployeeActions = () => {
  const [, setStore] = useStore();
  const { api } = useApi();
  const { getUsersOrganization } = useOrganizationActions();

  const createEmployee = async (createEmployeeDto: ICreateEmployee) => {
    try {
      setStore((old) => ({
        ...old,
        api: { reading: true },
      }));
      await api.post("/auth/register", createEmployeeDto);
      await getUsersOrganization();
      setStore((old) => ({
        ...old,
        api: { reading: false },
      }));
      toast("Der Mitarbeiter wurde erfolgreich erstellt.", {
        type: "success",
        position: "top-right",
      });
    } catch (e) {
      if (e instanceof ValidationError || e instanceof LogicValidationError) {
        throw e;
      } else {
        console.error(e);
        toast("Ein unerwarteter Fehler ist aufgetreten.", {
          type: "error",
          position: "top-right",
        });
      }
    }
  };

  const deActivateEmployee = async (employeeId: string, activate: boolean) => {
    try {
      setStore((old) => ({
        ...old,
        api: { reading: true },
      }));
      await api.post("/user/" + employeeId, {
        active: activate,
      });
      await getUsersOrganization();
      setStore((old) => ({
        ...old,
        api: { reading: false },
      }));
      toast(
        "Der Mitarbeiter wurde erfolgreich " +
          (activate ? "aktiviert" : "deaktiviert") +
          ".",
        {
          type: "success",
          position: "top-right",
        }
      );
    } catch (e) {
      if (e instanceof ValidationError || e instanceof LogicValidationError) {
        throw e;
      } else {
        console.error(e);
        toast("Ein unerwarteter Fehler ist aufgetreten.", {
          type: "error",
          position: "top-right",
        });
      }
    }
  };

  const updateEmployee = async (
    employeeId: string,
    updateEmployeeDto: IUpdateEmployee
  ) => {
    try {
      setStore((old) => ({
        ...old,
        api: { reading: true },
      }));
      await api.post("/user/" + employeeId, updateEmployeeDto);
      await getUsersOrganization();
      setStore((old) => ({
        ...old,
        api: { reading: false },
      }));
      toast("Der Mitarbeiter wurde erfolgreich bearbeitet.", {
        type: "success",
        position: "top-right",
      });
    } catch (e) {
      if (e instanceof ValidationError || e instanceof LogicValidationError) {
        throw e;
      } else {
        console.error(e);
        toast("Ein unerwarteter Fehler ist aufgetreten.", {
          type: "error",
          position: "top-right",
        });
      }
    }
  };

  const deleteEmployee = async (employeeId: string) => {
    try {
      setStore((old) => ({
        ...old,
        api: { reading: true },
      }));
      await api.remove("/user/" + employeeId);
      await getUsersOrganization();
      setStore((old) => ({
        ...old,
        api: { reading: false },
      }));
      toast("Der Mitarbeiter wurde erfolgreich gelöscht.", {
        type: "success",
        position: "top-right",
      });
    } catch (e) {
      if (e instanceof ValidationError || e instanceof LogicValidationError) {
        throw e;
      } else {
        console.error(e);
        toast("Ein unerwarteter Fehler ist aufgetreten.", {
          type: "error",
          position: "top-right",
        });
      }
    }
  };

  return {
    createEmployee,
    updateEmployee,
    deActivateEmployee,
    deleteEmployee,
  };
};
