import {
  faSort,
  faSortDown,
  faSortUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import { FC, useState } from "react";
import { Table as BTable } from "react-bootstrap";
import { IUser, useStore } from "../../store";
import EmptyList from "../../shared/ui/EmptyList";

interface EmployeeSuccessSumTableProps {
  data: IUser[];
}

const columnHelper = createColumnHelper<IUser>();

const columns = [
  columnHelper.accessor("firstname", {
    header: "Vorname",
  }),
  columnHelper.accessor("lastname", {
    header: "Nachname",
  }),
  columnHelper.accessor("totalSalesSuccess", {
    header: "Proben",
  }),
];

const EmployeeSuccessSumTable: FC<EmployeeSuccessSumTableProps> = (
  props: EmployeeSuccessSumTableProps
) => {
  const [store, setStore] = useStore();
  const [sorting, setSorting] = useState<SortingState>([]);
  const table = useReactTable<IUser>({
    data: props.data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  if (props.data.length === 0) {
    return <EmptyList name="Mitarbeiter Probenranking" />;
  }

  return (
    <BTable hover responsive>
      <thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => {
              return (
                <th key={header.id} colSpan={header.colSpan}>
                  {header.isPlaceholder ? null : (
                    <div
                      {...{
                        role: header.column.getCanSort() ? "button" : "",
                        onClick: header.column.getToggleSortingHandler(),
                      }}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                      {{
                        asc: (
                          <FontAwesomeIcon icon={faSortUp}></FontAwesomeIcon>
                        ),
                        desc: (
                          <FontAwesomeIcon icon={faSortDown}></FontAwesomeIcon>
                        ),
                      }[header.column.getIsSorted() as string] ?? (
                        <FontAwesomeIcon
                          icon={faSort}
                          className="text-muted"
                        ></FontAwesomeIcon>
                      )}
                    </div>
                  )}
                </th>
              );
            })}
          </tr>
        ))}
      </thead>
      <tbody>
        {table.getRowModel().rows.map((row) => (
          <tr key={row.id}>
            {row.getVisibleCells().map((cell) => (
              <td key={cell.id}>
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </BTable>
  );
};

export default EmployeeSuccessSumTable;
