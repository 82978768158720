import { FC, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAreaActions } from "../../actions/areaActions";
import Area from "../components/AreaDetail";
import { useStore } from "../../store";
import FullContainer from "../../shared/ui/FullContainer";

const AreaDetail: FC = () => {
  const [store, setStore] = useStore();
  let { id } = useParams();
  const { getAllAreas } = useAreaActions();

  useEffect(() => {
    getAllAreas();
  }, []);

  const area = store.areas.find((area) => area.id === id);
  if (!store.areas || store.areas.length === 0) {
    return <FullContainer>Laden...</FullContainer>;
  }

  if (!area) {
    return <FullContainer>Gebiet nicht gefunden</FullContainer>;
  }

  return (
    <FullContainer>
      <Area area={area}></Area>
    </FullContainer>
  );
};

export default AreaDetail;
