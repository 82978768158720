import { FC, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useAreaActions } from "../../actions/areaActions";
import { useOrganizationActions } from "../../actions/organizationActions";
import { IArea, IUser, useStore } from "../../store";
import InputField from "../../shared/form/InputField";
import MultiSelect from "../../shared/form/MultiSelect";
import Textarea from "../../shared/form/Textarea";
import Button from "../../shared/ui/Button";

type UpdateAreaForm = {
  name: string;
  zipCode: string;
  city: string;
  comment: string;
  noOfHouses: number;
  promoters: string[];
};

interface AreaEditFormProps {
  area: IArea;
  employees: IUser[];
}

const AreaEditForm: FC<AreaEditFormProps> = (props: AreaEditFormProps) => {
  const [store] = useStore();
  const [loading, setLoading] = useState<boolean>(false);
  const { getUsersOrganization } = useOrganizationActions();
  const navigate = useNavigate();
  const methods = useForm<UpdateAreaForm>({
    defaultValues: {
      name: props.area.name,
      zipCode: props.area.zipCode,
      city: props.area.city,
      noOfHouses: props.area.noOfHouses,
      comment: props.area.comment,
      promoters: props.area.promoters.map((promoter) => promoter.id),
    },
  });

  const { updateArea } = useAreaActions();
  const onSubmit = async (data: UpdateAreaForm) => {
    const areaId = props.area.id;
    if (areaId) {
      setLoading(true);
      await updateArea(areaId, {
        name: data.name,
        city: data.city,
        zipCode: data.zipCode,
        promoters: data.promoters.flatMap((promoter) => ({
          id: promoter,
        })),
        noOfHouses: data.noOfHouses,
        comment: data.comment,
      });
      setLoading(false);
      navigateToAreas();
    }
  };

  const navigateToAreas = () => {
    navigate("/areas");
  };

  useEffect(() => {
    methods.reset({
      name: props.area?.name,
      zipCode: props.area?.zipCode,
      city: props.area?.city,
      comment: props.area?.comment,
      promoters: props.area?.promoters.map((promoter) => promoter.id),
    });
  }, [methods.reset, props.area, store.organization]);

  useEffect(() => {
    getUsersOrganization();
  }, []);

  const employees = store.organization?.employees;

  return (
    <FormProvider {...methods}>
      <Form onSubmit={methods.handleSubmit(onSubmit)}>
        <InputField label="Name" registerName="name" required></InputField>
        <InputField label="Ort" registerName="city" required></InputField>
        <InputField
          label="Postleitzahl"
          registerName="zipCode"
          required
        ></InputField>
        <InputField
          label="Anzahl Häuser"
          registerName="noOfHouses"
          type="number"
        ></InputField>
        <MultiSelect label="Aktive Promoter" registerName="promoters">
          {employees?.map((employee: IUser) => (
            <option value={employee.id}>
              {employee.firstname} {employee.lastname}
            </option>
          ))}
        </MultiSelect>
        <Textarea label="Kommentar" registerName="comment"></Textarea>
        <Form.Group>
          <Button
            variant="secondary"
            type="button"
            onClick={() => navigateToAreas()}
          >
            Abbrechen
          </Button>
          <Button
            variant="primary"
            type="submit"
            style={{ marginLeft: "10px" }}
            loading={loading}
          >
            Speichern
          </Button>
        </Form.Group>
      </Form>
    </FormProvider>
  );
};

export default AreaEditForm;
