import { IArea, IUser } from "../store";

export const isActivePromoter = (area: IArea, user: IUser | undefined) => {
  if (!user) {
    return false;
  }
  const promoter = area.promoters?.find(
    (promoter: IUser) => promoter.id === user?.id
  );
  return promoter ? true : false;
};
