import { CSSProperties, FC, MouseEventHandler, ReactNode } from "react";
import { Button as BButton } from "react-bootstrap";
import { ButtonVariant } from "react-bootstrap/esm/types";

interface ButtonProps {
  children?: ReactNode;
  loading?: boolean;
  variant?: ButtonVariant;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  type?: "button" | "submit" | "reset";
  style?: CSSProperties;
  className?: string;
  disabled?: boolean;
}

const Button: FC<ButtonProps> = (
  props: ButtonProps = {
    variant: "primary",
    type: "button",
    loading: false,
  }
) => {
  if (props.loading) {
    return (
      <BButton
        variant={props.variant}
        disabled
        className={props.className || "fw-bold cursor-hover bg-gradient"}
        type={props.type}
        style={props.style}
      >
        <span
          className="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>{" "}
        Laden ...
      </BButton>
    );
  }
  return (
    <BButton
      onClick={props.onClick}
      variant={props.variant}
      className={props.className || "fw-bold cursor-hover bg-gradient"}
      type={props.type}
      style={props.style}
      disabled={props.disabled || false}
    >
      {props.children}
    </BButton>
  );
};

export default Button;
