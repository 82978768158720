import { faArrowLeftLong } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CSSProperties, FC, MouseEventHandler, ReactNode } from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

interface BackButtonProps {
  onClick?: MouseEventHandler<HTMLButtonElement>;
  style?: CSSProperties;
}

const BackButton: FC<BackButtonProps> = (props: BackButtonProps) => {
  const navigate = useNavigate();
  const onClickBack = () => navigate(-1);

  return (
    <Button
      onClick={props.onClick ?? onClickBack}
      variant="primary"
      className="fw-bold cursor-hover mb-3 bg-gradient"
      style={props.style}
    >
      <FontAwesomeIcon
        style={{ marginRight: "10" }}
        icon={faArrowLeftLong}
      ></FontAwesomeIcon>
      Zurück
    </Button>
  );
};

export default BackButton;
