import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC } from "react";
import {
  Accordion,
  Form,
  InputGroup,
  useAccordionButton,
} from "react-bootstrap";
import { useFormContext, UseFormSetValue } from "react-hook-form";
import { ErrorTypes } from "./ErrorTypes";

interface ToggableInputFieldProps {
  registerName: string;
  label: string;
  type?: string;
  errorMessages?: ErrorTypes;
  defaultOpen?: boolean;
}

const CustomToggle = (props: {
  setValue: UseFormSetValue<any>;
  label: string;
  registerName: string;
}) => {
  const decoratedOnClick = useAccordionButton("0", (eventKey) => {
    props.setValue(props.registerName, undefined);
  });
  return (
    <Form.Label className="fw-bold cursor-hover" onClick={decoratedOnClick}>
      <h5>
        <FontAwesomeIcon className="mx-2" icon={faPlusCircle}></FontAwesomeIcon>
        {props.label}
      </h5>
    </Form.Label>
  );
};

const ToggableInputField: FC<ToggableInputFieldProps> = (
  props: ToggableInputFieldProps
) => {
  const {
    register,
    setValue,
    formState: { errors },
  } = useFormContext();

  return (
    <Form.Group className="mb-3">
      <Accordion defaultActiveKey={props.defaultOpen === true ? "0" : ""}>
        <CustomToggle
          label={props.label}
          registerName={props.registerName}
          setValue={setValue}
        ></CustomToggle>
        <Accordion.Collapse eventKey="0">
          <InputGroup className="mb-3">
            <Form.Control
              type={props.type || "text"}
              {...register(props.registerName, {
                valueAsNumber: props.type === "number",
              })}
              isInvalid={errors[props.registerName] ? true : false}
            />
            <InputGroup.Text>Minuten</InputGroup.Text>
          </InputGroup>
        </Accordion.Collapse>
      </Accordion>
    </Form.Group>
  );
};

export default ToggableInputField;
