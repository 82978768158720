import { FC, ReactNode } from "react";
import { Col, Row } from "react-bootstrap";
import { ListRowElement } from "./List";

interface SmallListProps {
  data: ListRowElement[];
}

export const SmallList: FC<SmallListProps> = (props: SmallListProps) => {
  return (
    <>
      {props.data.map((d: ListRowElement, index: number) => (
        <div key={d.name + index}>
          <Row>
            <Col xs={6} className="fw-bold">
              {d.name}
            </Col>
            <Col xs={6} style={{ whiteSpace: "pre-wrap" }}>
              {d.value}
            </Col>
          </Row>
          {index + 1 !== props.data.length && <hr />}
        </div>
      ))}
    </>
  );
};
