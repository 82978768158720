import { FC, ReactNode } from "react";
import { Button as BButton } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";

interface NavigationButtonProps {
  children?: ReactNode;
  path: string;
}

const NavigationButton: FC<NavigationButtonProps> = (
  props: NavigationButtonProps
) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  let match = false;
  if (props.path === "/") {
    match = pathname === "/" ? true : false;
  } else if (props.path !== "/" && pathname === "/") {
    match = false;
  } else {
    match = props.path.includes(splitLocation[1]);
  }

  if (match) {
    return (
      <BButton
        variant="primary"
        onClick={() => navigate(props.path)}
        className="mx-1 bg-gradient"
      >
        {props.children}
      </BButton>
    );
  }

  return (
    <BButton
      className="mx-1 my-1"
      variant="outline"
      onClick={() => navigate(props.path)}
    >
      {props.children}
    </BButton>
  );
};

export default NavigationButton;
