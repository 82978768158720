import { FC, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useOrganizationActions } from "../../actions/organizationActions";
import Employee from "../components/EmployeeDetail";
import { useStore } from "../../store";
import FullContainer from "../../shared/ui/FullContainer";

const EmployeeDetail: FC = () => {
  const [store, setStore] = useStore();
  let { id } = useParams();
  const { getUsersOrganization } = useOrganizationActions();

  useEffect(() => {
    getUsersOrganization();
  }, []);

  const employee = store.organization?.employees?.find(
    (employee) => employee.id === id
  );
  if (!store.organization || store.organization?.employees.length === 0) {
    return <FullContainer>Laden...</FullContainer>;
  }

  if (!employee) {
    return <FullContainer>Mitarbeiter nicht gefunden.</FullContainer>;
  }

  return (
    <FullContainer>
      <Employee employee={employee}></Employee>
    </FullContainer>
  );
};

export default EmployeeDetail;
