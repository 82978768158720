import { FC, ReactNode } from "react";
import { Card as BCard } from "react-bootstrap";

interface CardProps {
  children: ReactNode;
}

const Card: FC<CardProps> = (props: CardProps) => {
  return (
    <BCard
      className="mb-4"
      style={{ backgroundColor: "#FFFFFF", minHeight: "140px" }}
    >
      <BCard.Body>{props.children}</BCard.Body>
    </BCard>
  );
};

export default Card;
