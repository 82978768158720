import { FC, useState } from "react";
import { IArea } from "../../store";
import { Alert, Col, Dropdown, Row } from "react-bootstrap";
import { AreaStatusBadge } from "./AreaStatusBadge";
import MapThumbnail from "./MapThumbnail";
import { generateMissionsView } from "../../utils/views";
import MissionsTable from "../../missions/components/MissionsTable";
import { formatDateAndTime } from "../../utils/formatDate";
import EmojiBig from "../../shared/ui/EmojiBig";
import ConfirmDeleteModal, {
  ConfirmDeleteModalState,
} from "../../shared/ui/ConfirmDeleteModal";
import { useAreaActions } from "../../actions/areaActions";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInfoCircle,
  faLock,
  faLockOpen,
  faPenToSquare,
  faSquareCheck,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import HeadlineCard from "../../shared/ui/HeadlineCard";
import DashboardCard from "../../shared/ui/DashboardCard";
import Subline from "../../shared/ui/Subline";
import Card from "../../shared/ui/Card";
import BackButton from "../../shared/ui/BackButton";
import Headline from "../../shared/ui/Headline";
import ThreeDotToggle from "../../shared/ui/ThreeDotToggle";
import { List, ListRowElement } from "../../shared/ui/List";
import { roundToDecimals } from "../../utils/roundToDecimels";
import { formatHours } from "../../utils/formatHours";

interface AreaDetailProps {
  area: IArea;
}

const AreaDetail: FC<AreaDetailProps> = (props: AreaDetailProps) => {
  const [confirmDeleteModalState, setConfirmDeleteModalState] =
    useState<ConfirmDeleteModalState>({
      open: false,
    });
  const navigate = useNavigate();
  const { deleteArea, blockArea, completeArea } = useAreaActions();

  const onDeleteArea = async (id?: string) => {
    if (id) {
      await deleteArea(id);
      setConfirmDeleteModalState({
        open: false,
        itemId: undefined,
      });
    }
  };

  const onEditArea = async (id: string) => {
    navigate("/areas/" + id + "/edit");
  };

  const listElements: ListRowElement[] = [
    {
      name: "Name",
      value: props.area.name,
    },
    {
      name: "Ort",
      value: props.area.city,
    },
    {
      name: "Postleitzahl",
      value: props.area.zipCode,
    },
    {
      name: "Aktive Promoter",
      value: props.area.promoters
        .map((promoter) => promoter.firstname + " " + promoter.lastname)
        .join(", "),
    },
    {
      name: "Status",
      value: <AreaStatusBadge status={props.area.status}></AreaStatusBadge>,
    },
    {
      name: "Zuletzt abgeschlossen",
      value: props.area.completedAt
        ? formatDateAndTime(props.area.completedAt)
        : "/",
    },
    {
      name: "Anzahl Häuser",
      value: props.area.noOfHouses ? props.area.noOfHouses : "/",
    },
    {
      name: "Kommentar",
      value: props.area.comment ? props.area.comment : "/",
    },
    {
      name: "Zuletzt geändert am",
      value: formatDateAndTime(props.area.updatedAt),
    },
    {
      name: "Erstellt am",
      value: formatDateAndTime(props.area.createdAt),
    },
  ];

  return (
    <>
      <Row>
        <Col>
          <BackButton />
        </Col>
      </Row>
      {props.area.isBlocked && (
        <Row>
          <Col>
            <Alert
              variant="secondary"
              className="bg-secondary bg-gradient text-white fw-bold align-middle"
            >
              <FontAwesomeIcon
                icon={faInfoCircle}
                size="lg"
                style={{ marginRight: "15px" }}
              ></FontAwesomeIcon>
              Dieses Gebiet ist gesperrt.
            </Alert>
          </Col>
        </Row>
      )}
      <Row>
        <Col xs={12} md={7}>
          <Card>
            <Row>
              <Col>
                <Headline>
                  Gebiet <span className="fw-light">{props.area.name}</span>
                </Headline>
              </Col>
              <Col className="col-auto">
                <Dropdown drop="down">
                  <Dropdown.Toggle as={ThreeDotToggle}></Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      as="button"
                      onClick={() => onEditArea(props.area.id)}
                    >
                      <FontAwesomeIcon
                        style={{ marginRight: 10 }}
                        icon={faPenToSquare}
                      />
                      Bearbeiten
                    </Dropdown.Item>
                    <Dropdown.Divider />
                    {props.area.status !== "completed" &&
                      props.area.status !== "blocked" && (
                        <Dropdown.Item
                          onClick={() => completeArea(props.area.id)}
                        >
                          <FontAwesomeIcon
                            style={{ marginRight: 10 }}
                            icon={faSquareCheck}
                          />
                          Abschließen
                        </Dropdown.Item>
                      )}
                    <Dropdown.Item
                      onClick={() =>
                        blockArea(
                          props.area.id,
                          props.area.isBlocked ? false : true
                        )
                      }
                    >
                      <FontAwesomeIcon
                        style={{ marginRight: 10 }}
                        icon={props.area.isBlocked ? faLockOpen : faLock}
                      />
                      {props.area.isBlocked ? "Entsperren" : "Sperren"}
                    </Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item
                      as="button"
                      onClick={() =>
                        setConfirmDeleteModalState({
                          open: true,
                          itemId: props.area.id,
                        })
                      }
                    >
                      <FontAwesomeIcon
                        style={{ marginRight: 10 }}
                        icon={faTrash}
                      />
                      Löschen
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
            </Row>
            <List data={listElements}></List>
          </Card>
        </Col>
        <Col xs={12} md={5}>
          <Card>
            <MapThumbnail area={props.area}></MapThumbnail>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={3}>
          <DashboardCard
            subline="Proben Gesamt"
            headline={String(props.area.totalSalesSuccess)}
          />
        </Col>
        <Col xs={12} md={3}>
          <DashboardCard
            subline="Einsätze Gesamt"
            headline={String(props.area.missions.length)}
          />
        </Col>
        <Col xs={12} md={3}>
          <DashboardCard
            subline="Vertriebszeit Gesamt"
            headline={formatHours(props.area.totalSalesDuration)}
          />
        </Col>
        <Col xs={12} md={3}>
          <DashboardCard
            subline="Schnitt"
            headline={String(props.area.salesSuccessPerHour)}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={3}>
          <DashboardCard
            subline="Proben %"
            headline={
              roundToDecimals(props.area.percentageSalesSuccess * 100, 2) + "%"
            }
          />
        </Col>
        <Col xs={12} md={3}>
          <DashboardCard
            subline="Kein Bedarf %"
            headline={
              roundToDecimals(props.area.percentageSalesFailed * 100, 2) + "%"
            }
          />
        </Col>
        <Col xs={12} md={3}>
          <DashboardCard
            subline="Nicht Angetroffen %"
            headline={
              roundToDecimals(props.area.percentageSalesNotAvailable * 100, 2) +
              "%"
            }
          />
        </Col>
        <Col xs={12} md={3}>
          <Card>
            <Subline>Stimmung</Subline>
            <EmojiBig rating={props.area.avgRating}></EmojiBig>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <HeadlineCard headline="Einsätze">
            <MissionsTable
              data={generateMissionsView([props.area])}
            ></MissionsTable>
          </HeadlineCard>
        </Col>
      </Row>

      <ConfirmDeleteModal
        open={confirmDeleteModalState.open}
        nameInText={"das Gebiet"}
        nameInHeadline={"Gebiet"}
        onSuccess={() => onDeleteArea(confirmDeleteModalState.itemId)}
        onAbort={() =>
          setConfirmDeleteModalState({ open: false, itemId: undefined })
        }
      ></ConfirmDeleteModal>
    </>
  );
};

export default AreaDetail;
