import { FC, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useOrganizationActions } from "../../actions/organizationActions";
import Card from "../../shared/ui/Card";
import Headline from "../../shared/ui/Headline";
import UpdateEmployeeForm from "../components/EmployeeEditForm";
import { useStore } from "../../store";
import FullContainer from "../../shared/ui/FullContainer";

const EmployeeEdit: FC = () => {
  const [store, setStore] = useStore();
  let { id } = useParams();
  const { getUsersOrganization } = useOrganizationActions();

  useEffect(() => {
    getUsersOrganization();
  }, []);

  const employees = store.organization?.employees;
  const employee = employees?.find((employee) => employee.id === id);

  if (!store.organization || store.organization.employees?.length === 0) {
    return <FullContainer>Laden...</FullContainer>;
  }

  if (!employee || !employees) {
    return <FullContainer>Mitarbeiter nicht gefunden.</FullContainer>;
  }

  return (
    <FullContainer>
      <Card>
        <Headline>
          Mitarbeiter{" "}
          <span className="fw-light">
            {employee.firstname + " " + employee.lastname}
          </span>{" "}
          bearbeiten
        </Headline>
        <UpdateEmployeeForm employee={employee}></UpdateEmployeeForm>
      </Card>
    </FullContainer>
  );
};

export default EmployeeEdit;
