import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useStore } from "../store";
import { Api } from "./api";

export const useApi = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [, setStore] = useStore();

  const noAuthRedirection = (url: string) => {
    console.log("Unauthorized API call, redirecting to login");

    if (localStorage.getItem("accessToken")) {
      toast("Sie wurden automatisch ausgeloggt.", {
        type: "warning",
        position: "top-right",
      });
      localStorage.removeItem("accessToken");
    }
    setStore((old) => ({
      ...old,
      accessToken: undefined,
      accessTokenPayload: undefined,
    }));
    navigate("/login", { state: { from: location }, replace: true });
  };

  return {
    api: new Api(
      process.env.REACT_APP_BACKEND_URL || "http://localhost:3000",
      noAuthRedirection,
      localStorage.getItem("accessToken") || undefined
    ),
  };
};
