import { FC, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import FullContainer from "../../shared/ui/FullContainer";
import { IMission, useStore } from "../../store";
import { useAreaActions } from "../../actions/areaActions";
import { isAdmin } from "../../utils/isRole";
import { getActiveUser } from "../../utils/activeUser";
import { useOrganizationActions } from "../../actions/organizationActions";
import EmojiBig from "../../shared/ui/EmojiBig";
import { isThisMonth } from "../../utils/isThisMonth";
import { unique } from "../../utils/unique";
import EmployeeSuccessPerHourTable from "../components/EmployeeSuccessPerHourTable";
import EmployeeSuccessSumTable from "../components/EmployeeSuccessSumTable";
import DashboardCard from "../../shared/ui/DashboardCard";
import HeadlineCard from "../../shared/ui/HeadlineCard";
import Headline from "../../shared/ui/Headline";
import Card from "../../shared/ui/Card";
import Subline from "../../shared/ui/Subline";
import { formatHours } from "../../utils/formatHours";

const Home: FC = () => {
  const [store, _] = useStore();
  const { getAllAreas } = useAreaActions();
  const { getUsersOrganization } = useOrganizationActions();

  useEffect(() => {
    getAllAreas();
    getUsersOrganization();
  }, []);

  const missions = store.areas.flatMap((area) =>
    area.missions.map((mission) => ({ ...mission, area: area.id }))
  );

  const usersMissions = store.areas.flatMap((area) =>
    area.missions
      .filter((mission) => mission.finisher.id === getActiveUser(store)?.id)
      .map((mission) => ({ ...mission, area: area.id }))
  );

  const usersMissionsMonth = usersMissions.filter((mission) =>
    isThisMonth(mission.salesStart)
  );

  const missionsMonth = missions.filter((mission) =>
    isThisMonth(mission.salesStart)
  );

  if (isAdmin(store.accessTokenPayload)) {
    return (
      <FullContainer>
        <Headline>Hallo {getActiveUser(store)?.firstname}!</Headline>
        <Row>
          <Col lg="6">
            <DashboardCard
              headline={String(
                missionsMonth.reduce(
                  (a: number, c: IMission) => a + c.salesSuccess,
                  0
                )
              )}
              subline="Proben Gesamt diesen Monat"
            />
          </Col>
          <Col lg="6">
            <DashboardCard
              subline="Vertriebsstunden Gesamt diesen Monat"
              headline={String(
                formatHours(
                  missionsMonth.reduce(
                    (a: number, c: IMission) => a + c.salesDuration,
                    0
                  )
                )
              )}
            />
          </Col>
        </Row>
        <Row>
          <Col lg="6">
            <DashboardCard
              subline="Einsätze diesen Monat"
              headline={String(missionsMonth.length)}
            ></DashboardCard>
          </Col>
          <Col lg="6">
            <DashboardCard
              subline="Aktive Promoter diesen Monat"
              headline={String(
                unique(missionsMonth.flatMap((mission) => mission.finisher.id))
                  .length
              )}
            ></DashboardCard>
          </Col>
        </Row>
        <Row>
          <Col>
            <HeadlineCard headline="Mitarbeiterranking Schnitt">
              <EmployeeSuccessPerHourTable
                data={store.organization?.employees || []}
              />
            </HeadlineCard>
          </Col>
        </Row>
        <Row>
          <Col>
            <HeadlineCard headline="Mitarbeiterranking Proben">
              <EmployeeSuccessSumTable
                data={store.organization?.employees || []}
              />
            </HeadlineCard>
          </Col>
        </Row>
      </FullContainer>
    );
  }

  return (
    <FullContainer>
      <Headline>Hallo {getActiveUser(store)?.firstname}!</Headline>
      <Row>
        <Col lg="6">
          <DashboardCard
            subline="Proben Gesamt"
            headline={String(
              usersMissions.reduce(
                (a: number, c: IMission) => a + c.salesSuccess,
                0
              )
            )}
          ></DashboardCard>
        </Col>
      </Row>
      <Row>
        <Col lg="6">
          <DashboardCard
            subline="Deine Einsätze diesen Monat"
            headline={String(usersMissionsMonth.length)}
          ></DashboardCard>
        </Col>
        <Col lg="6">
          <DashboardCard
            subline="Deine gesammelten Proben diesen Monat"
            headline={String(
              usersMissionsMonth.reduce(
                (a: number, c: IMission) => a + c.salesSuccess,
                0
              )
            )}
          ></DashboardCard>
        </Col>
      </Row>
      <Row>
        <Col lg="6">
          <DashboardCard
            subline="Deine abgeschlossenen Gebiete diesen Monat"
            headline={String(
              unique(usersMissionsMonth.map((mission) => mission.area)).length
            )}
          ></DashboardCard>
        </Col>
        <Col lg="6">
          <Card>
            <Subline>Deine Stimmung diesen Monat</Subline>
            <EmojiBig
              rating={Math.round(
                usersMissionsMonth.reduce(
                  (a: number, c: IMission) => a + 3,
                  0
                ) / usersMissionsMonth.length
              )}
            ></EmojiBig>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <HeadlineCard headline="Mitarbeiterranking Proben">
            <EmployeeSuccessSumTable
              data={store.organization?.employees || []}
            />
          </HeadlineCard>
        </Col>
      </Row>
    </FullContainer>
  );
};

export default Home;
