import { FC } from "react";
import { Badge } from "react-bootstrap";

interface EmployeeStatusBadgeProps {
  active: boolean;
}

export const EmployeeStatusBadge: FC<EmployeeStatusBadgeProps> = (
  props: EmployeeStatusBadgeProps
) => {
  if (!props.active) {
    return (
      <Badge
        bg="secondary"
        className="bg-secondary bg-gradient rounded-pill mb-2"
      >
        Inaktiv
      </Badge>
    );
  }

  return (
    <Badge bg="success" className="bg-success bg-gradient rounded-pill mb-2">
      Aktiv
    </Badge>
  );
};
