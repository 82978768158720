const ROLE_ADMIN = "admin";
const ROLE_PROMOTER = "promoter";
const ROLE_TEAMLEADER = "teamleader";

export const isAdmin = (payload: { role?: string }) =>
  payload.role === ROLE_ADMIN;

export const isAdminOrTeamlead = (payload: { role?: string }) =>
  payload.role === ROLE_ADMIN || payload.role === ROLE_TEAMLEADER;

export const isPromoter = (payload: { role?: string }) =>
  payload.role === ROLE_PROMOTER;

export const isTeamlead = (payload: { role?: string }) =>
  payload.role === ROLE_TEAMLEADER;

export const isPromoterOrTeamlead = (payload: { role?: string }) =>
  payload.role === ROLE_PROMOTER || payload.role === ROLE_TEAMLEADER;
