import { IArea, IMission } from "../store";
import { formatDateAndTime } from "./formatDate";
import {
  calculateSuccessPerHourForMission,
  calculateTotalHoursForMissions,
  calculateTotalSuccessForMissions,
} from "./missionMetrics";
import { roundToDecimals } from "./roundToDecimels";

export type IAreaView = {
  id: string;
  name: string;
  activePromoters: string;
  status: "notAssigned" | "active" | "completed";
  sumSuccess: number;
  avgSuccessPerHour: number;
  completedAt: string;
};

export type IMissionView = {
  id: string;
  area: {
    name: string;
    city: string;
    zipCode: string;
  };
  promoter: string;
  promoterId: string;
  salesSuccess: number;
  salesSuccessPerHour: number;
  salesStartDate: string;
  salesEndDate: string;
  createdAt: string;
  createdAt_org: string;
};

export const generateAreasView = (areas: IArea[]) => {
  return areas.map((area) => {
    const totalHours = calculateTotalHoursForMissions(area.missions);
    const totalSuccess = calculateTotalSuccessForMissions(area.missions);

    const avgSuccessPerHour =
      totalHours != 0 ? roundToDecimals(totalSuccess / totalHours, 2) : 0;

    return {
      ...area,
      avgSuccessPerHour: avgSuccessPerHour,
      sumSuccess: totalSuccess,
      activePromoters: area.promoters
        .map((p) => p.firstname + " " + p.lastname)
        .join(", "),
    };
  });
};

export const generateMissionsView = (areas: IArea[]) => {
  return areas
    .flatMap((area) => {
      return area.missions.map((mission: IMission) => {
        return {
          ...mission,
          area: { name: area.name, city: area.city, zipCode: area.zipCode },
          promoter:
            mission.finisher.firstname + " " + mission.finisher.lastname,
          promoterId: mission.finisher.id,
          salesStartDate: formatDateAndTime(mission.salesStart),
          salesEndDate: formatDateAndTime(mission.salesEnd),
          createdAt: formatDateAndTime(mission.createdAt),
          createdAt_org: mission.createdAt,
        };
      });
    })
    .sort(
      (a: IMissionView, b: IMissionView) =>
        new Date(b.createdAt_org).getTime() -
        new Date(a.createdAt_org).getTime()
    );
};
