import { FC } from "react";
import { Form } from "react-bootstrap";
import { useFormContext } from "react-hook-form";

interface TextareaProps {
  registerName: string;
  label: string;
  required?: boolean;
}

const Textarea: FC<TextareaProps> = (props: TextareaProps) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <Form.Group className="mb-3">
      <Form.Label className="fw-bold">
        <h5>{props.label + (props.required ? " *" : "")}</h5>
      </Form.Label>
      <Form.Control
        type="text"
        as="textarea"
        rows={8}
        {...register(props.registerName, {
          required: props.required ? "Bitte füllen Sie das Feld aus." : false,
        })}
        isInvalid={errors[props.registerName] ? true : false}
      />
      <Form.Control.Feedback type="invalid">
        <>{errors[props.registerName]?.message}</>
      </Form.Control.Feedback>
    </Form.Group>
  );
};

export default Textarea;
