import storeFactory from "./store-factory";
import Cookies from "universal-cookie";
import jwt_decode from "jwt-decode";

const cookies = new Cookies();

export interface IArea {
  id: string;
  name: string;
  polygon: [string, string][];
  promoters: IUser[];
  zipCode: string;
  city: string;
  missions: IMission[];
  completedAt: string;
  comment?: string;
  noOfHouses?: number;
  isBlocked: boolean;
  status: "notAssigned" | "active" | "completed" | "blocked";
  createdAt: string;
  updatedAt: string;
  totalSalesSuccess: number;
  totalSalesDuration: number;
  salesSuccessPerHour: number;
  percentageSalesSuccess: number;
  percentageSalesNotAvailable: number;
  percentageSalesFailed: number;
  totalMorningSales: number;
  totalEveningSales: number;
  avgRating: number;
}

export interface IMission {
  id: string;
  promoters: IUser[];
  finisher: IUser;
  salesSuccess: number;
  salesNotAvailable: number;
  salesFailed: number;
  comment?: string;
  documents: IDocument[];
  salesStart: string;
  salesEnd: string;
  salesDuration: number;
  drivingTime?: number;
  createdAt: string;
  updatedAt: string;
  salesSuccessPerHour: number;
  percentageSalesSuccess: number;
  percentageSalesNotAvailable: number;
  percentageSalesFailed: number;
  rating: number;
}

export interface IDocument {
  id: string;
  extension: string;
  contentType: string;
  name: string;
  path: string;
  createdAt: string;
  updatedAt: string;
}

export interface IOrganization {
  id: string;
  name: string;
  active: boolean;
  employees: IUser[];
  createdAt: string;
  updatedAt: string;
}

export interface IUser {
  id: string;
  alternativeId?: string;
  username: string;
  firstname: string;
  lastname: string;
  role: string;
  active: boolean;

  street?: string;
  houseNumber?: string;
  city?: string;
  zipCode?: string;
  country?: string;
  birthday?: string;

  telephone?: string;
  email?: string;

  iban?: string;
  bic?: string;
  additionalAgreement?: string;
  hourlyRate?: number;
  commission?: number;
  paymentCurrency?: string;

  enteredAt?: string;
  leftAt?: string;

  totalSalesSuccess: number;
  totalSalesDuration: number;
  salesSuccessPerHour: number;
  percentageSalesSuccess: number;
  percentageSalesNotAvailable: number;
  percentageSalesFailed: number;
  totalMorningSales: number;
  totalEveningSales: number;
  avgRating: number;
  totalMissionsCount: number;

  createdAt: string;
  updatedAt: string;
}

export interface ICoordinate {
  latitude: number;
  longitude: number;
}

export interface IApi {
  reading: boolean;
  writing: boolean;
}

export interface IModal {
  open: boolean;
  data?: { coordinates: ICoordinate[] };
}

export interface IStore {
  accessToken?: string;
  accessTokenPayload?: any;
  organization?: IOrganization;
  modal: IModal;
  areas: IArea[];
}

const storeDefault: IStore = {
  modal: {
    open: false,
  },
  areas: [],
  accessToken: localStorage.getItem("accessToken") || undefined,
  accessTokenPayload: localStorage.getItem("accessToken")
    ? jwt_decode(localStorage.getItem("accessToken")!)
    : undefined,
};

const { Provider: StoreProvider, useStore } = storeFactory<IStore>();

export { StoreProvider, useStore, storeDefault };
