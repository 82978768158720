import { FC, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAreaActions } from "../../actions/areaActions";
import { useOrganizationActions } from "../../actions/organizationActions";
import AreaEditForm from "../components/AreaEditForm";
import Card from "../../shared/ui/Card";
import Headline from "../../shared/ui/Headline";
import { useStore } from "../../store";
import FullContainer from "../../shared/ui/FullContainer";

const AreaEdit: FC = () => {
  const [store, setStore] = useStore();
  let { id } = useParams();
  const { getAllAreas } = useAreaActions();
  const { getUsersOrganization } = useOrganizationActions();

  useEffect(() => {
    getAllAreas();
    getUsersOrganization();
  }, []);

  const area = store.areas.find((area) => area.id === id);
  const employees = store.organization?.employees;

  if (
    !store.areas ||
    store.areas.length === 0 ||
    !store.organization ||
    store.organization.employees?.length === 0
  ) {
    return <FullContainer>Laden...</FullContainer>;
  }

  if (!area || !employees) {
    return <FullContainer>Gebiet nicht gefunden.</FullContainer>;
  }

  return (
    <FullContainer>
      <Card>
        <Headline>
          Gebiet <span className="fw-light">{area.name}</span> bearbeiten
        </Headline>
        <AreaEditForm area={area} employees={employees}></AreaEditForm>
      </Card>
    </FullContainer>
  );
};

export default AreaEdit;
