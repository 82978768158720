import { FC, useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import Dropzone from "react-dropzone";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { IArea } from "../../store";
import { useMissionActions } from "../../actions/missionActions";
import InputField from "../../shared/form/InputField";
import ToggableInputField from "../../shared/form/ToggableInputField";
import Textarea from "../../shared/form/Textarea";
import EmojiRating from "../../shared/form/EmojiRating";
import Button from "../../shared/ui/Button";
import FileInputField from "../../shared/form/FileInputField";

interface MissionCreateModalProps {
  open: boolean;
  area?: IArea;
  onSuccess: () => void;
  onAbort: () => void;
}

type CreateMissionForm = {
  comment: string;
  salesStart: string;
  salesEnd: string;
  salesSuccess: number;
  salesNotAvailable: number;
  salesFailed: number;
  drivingTime?: number;
  rating: number;
  files: FileList;
};

const MissionCreateModal: FC<MissionCreateModalProps> = (
  props: MissionCreateModalProps
) => {
  const methods = useForm<CreateMissionForm>();
  const [loading, setLoading] = useState<boolean>(false);
  const { createMission } = useMissionActions();

  useEffect(() => {
    methods.reset();
    setLoading(false);
  }, [props.open, methods]);

  const onSubmit = async (data: CreateMissionForm) => {
    setLoading(true);
    if (props.area) {
      let createMissionDto = {
        comment: data.comment,
        area: props.area?.id,
        promoters: props.area?.promoters.map((promoter) => ({
          id: promoter.id,
        })),
        salesFailed: Number(data.salesFailed),
        salesSuccess: Number(data.salesSuccess),
        salesNotAvailable: Number(data.salesNotAvailable),
        salesStart: new Date(data.salesStart).toISOString(),
        salesEnd: new Date(data.salesEnd).toISOString(),
        rating: data.rating,
        drivingTime: data.drivingTime ? Number(data.drivingTime) : 0,
      };
      await createMission(createMissionDto, data.files);
      props.onSuccess();
    }
    setLoading(false);
  };

  return (
    <Modal
      show={props.open}
      backdrop="static"
      keyboard={false}
      onHide={props.onAbort}
    >
      <Modal.Header closeButton>
        <Modal.Title>Einsatz erstellen</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FormProvider {...methods}>
          <Form onSubmit={methods.handleSubmit(onSubmit)}>
            <InputField
              label="Vertriebszeit Start"
              registerName="salesStart"
              type="datetime-local"
              required
            ></InputField>
            <InputField
              label="Vertriebszeit Ende"
              registerName="salesEnd"
              type="datetime-local"
              required
            ></InputField>
            <ToggableInputField
              label="Fahrzeit"
              registerName="drivingTime"
              type="number"
              defaultOpen={false}
            />
            <InputField
              label="Proben (PRO)"
              registerName="salesSuccess"
              type="number"
              required
            ></InputField>
            <InputField
              label="Nicht angetroffen (NA)"
              registerName="salesNotAvailable"
              type="number"
              required
            ></InputField>
            <InputField
              label="Kein Bedarf (KB)"
              registerName="salesFailed"
              type="number"
              required
            ></InputField>
            <EmojiRating label="Stimmung" registerName="rating"></EmojiRating>
            <FileInputField
              label="Dateien"
              registerName="files"
            ></FileInputField>
            <Textarea label="Kommentar" registerName="comment"></Textarea>
            <Form.Group>
              <Button variant="secondary" type="button" onClick={props.onAbort}>
                Abbrechen
              </Button>
              <Button
                variant="primary"
                type="submit"
                style={{ marginLeft: "10px" }}
                loading={loading}
              >
                Speichern
              </Button>
            </Form.Group>
          </Form>
        </FormProvider>
      </Modal.Body>
    </Modal>
  );
};

export default MissionCreateModal;
