import { FC } from "react";
import { Alert } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBoxArchive } from "@fortawesome/free-solid-svg-icons";

interface EmptyListProps {
  name: string;
}

const EmptyList: FC<EmptyListProps> = (props: EmptyListProps) => {
  return (
    <Alert
      variant="light"
      className="bg-light bg-gradient fw-bold align-middle"
    >
      <FontAwesomeIcon
        icon={faBoxArchive}
        size="lg"
        style={{ marginRight: "15px" }}
      ></FontAwesomeIcon>
      Bisher wurden keine {props.name} angelegt.
    </Alert>
  );
};

export default EmptyList;
