import { FC, ReactNode } from "react";
import { Col, Row } from "react-bootstrap";

export type ListRowElement = {
  name: string;
  value: ReactNode;
};

interface ListProps {
  data: ListRowElement[];
}

export const List: FC<ListProps> = (props: ListProps) => {
  return (
    <>
      {props.data.map((d: ListRowElement, index: number) => (
        <div key={d.name + index}>
          <Row>
            <Col xs={4}>
              <h5 className="mb-0">{d.name}</h5>
            </Col>
            <Col xs={8} style={{ whiteSpace: "pre-wrap" }}>
              {d.value}
            </Col>
          </Row>
          {index + 1 !== props.data.length && <hr />}
        </div>
      ))}
    </>
  );
};
