import { FC, useState } from "react";
import { IUser, useStore } from "../../store";
import { Alert, Col, Dropdown, Row } from "react-bootstrap";
import { formatDate, formatDateAndTime } from "../../utils/formatDate";
import ConfirmDeleteModal, {
  ConfirmDeleteModalState,
} from "../../shared/ui/ConfirmDeleteModal";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInfoCircle,
  faPenToSquare,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import Card from "../../shared/ui/Card";
import BackButton from "../../shared/ui/BackButton";
import Headline from "../../shared/ui/Headline";
import ThreeDotToggle from "../../shared/ui/ThreeDotToggle";
import { List, ListRowElement } from "../../shared/ui/List";
import DashboardCard from "../../shared/ui/DashboardCard";
import Subline from "../../shared/ui/Subline";
import EmojiBig from "../../shared/ui/EmojiBig";
import { roundToDecimals } from "../../utils/roundToDecimels";
import { EmployeeStatusBadge } from "./EmployeeStatusBadge";
import { isAdmin, isAdminOrTeamlead } from "../../utils/isRole";
import { formatHours } from "../../utils/formatHours";

interface EmployeeDetailProps {
  employee: IUser;
}

const EmployeeDetail: FC<EmployeeDetailProps> = (
  props: EmployeeDetailProps
) => {
  const [store, setStore] = useStore();
  const [confirmDeleteModalState, setConfirmDeleteModalState] =
    useState<ConfirmDeleteModalState>({
      open: false,
    });
  const navigate = useNavigate();

  const navigateToEditEmployee = (id: string) => {
    navigate("/employees/" + id + "/edit");
  };

  const onDeleteEmployee = async (id?: string) => {
    if (id) {
      setConfirmDeleteModalState({
        open: false,
        itemId: undefined,
      });
    }
  };

  const listElementsGeneral: ListRowElement[] = [
    {
      name: "Repräsentatennummer",
      value: props.employee.alternativeId ?? "/",
    },
    {
      name: "Nutzername",
      value: props.employee.username,
    },
    {
      name: "Status",
      value: <EmployeeStatusBadge active={props.employee.active} />,
    },
    {
      name: "Rolle",
      value: props.employee.role.toUpperCase() ?? "/",
    },
    {
      name: "Zuletzt geändert am",
      value: formatDateAndTime(props.employee.updatedAt),
    },
    {
      name: "Erstellt am",
      value: formatDateAndTime(props.employee.createdAt),
    },
  ];

  const listElementsProfile: ListRowElement[] = [
    {
      name: "Vorname",
      value: props.employee.firstname,
    },
    {
      name: "Nachname",
      value: props.employee.lastname,
    },
    {
      name: "Geburtstag",
      value: props.employee.birthday
        ? formatDate(props.employee.birthday)
        : "/",
    },
    {
      name: "Adresse",
      value:
        (props.employee.street ?? "/") +
        " " +
        (props.employee.houseNumber ?? "") +
        "\n" +
        (props.employee.city ?? "") +
        " " +
        (props.employee.zipCode ?? ""),
    },
    {
      name: "Telefonnummer",
      value: props.employee.telephone ?? "/",
    },
    {
      name: "E-Mail Adresse",
      value: props.employee.email ?? "/",
    },
  ];

  const listElementsPayment: ListRowElement[] = [
    {
      name: "IBAN",
      value: props.employee.iban ?? "/",
    },
    {
      name: "BIC",
      value: props.employee.bic ?? "/",
    },
  ];

  const listElementsContract: ListRowElement[] = [
    {
      name: "Eingestellt am",
      value: props.employee.enteredAt
        ? formatDateAndTime(props.employee.enteredAt)
        : "/",
    },
    {
      name: "Entlassen am",
      value: props.employee.leftAt
        ? formatDateAndTime(props.employee.leftAt)
        : "/",
    },
    {
      name: "Stundenlohn",
      value: props.employee.hourlyRate ?? "/",
    },
    {
      name: "Provision",
      value: props.employee.commission ?? "/",
    },
    {
      name: "Zusatzvereinbarung",
      value: props.employee.additionalAgreement ?? "/",
    },
  ];

  return (
    <>
      {isAdminOrTeamlead(store.accessTokenPayload) && (
        <Row>
          <Col>
            <BackButton />
          </Col>
        </Row>
      )}
      {!props.employee.active && (
        <Row>
          <Col>
            <Alert
              variant="secondary"
              className="bg-secondary bg-gradient text-white fw-bold align-middle"
            >
              <FontAwesomeIcon
                icon={faInfoCircle}
                size="lg"
                style={{ marginRight: "15px" }}
              ></FontAwesomeIcon>
              Dieses Nutzer ist inaktiv.
            </Alert>
          </Col>
        </Row>
      )}
      <Row>
        <Col>
          <Card>
            <Row>
              <Col>
                <Headline>
                  Mitarbeiter{" "}
                  <span className="fw-light">
                    {props.employee.firstname + " " + props.employee.lastname}
                  </span>
                </Headline>
              </Col>
              {isAdmin(store.accessTokenPayload) && (
                <Col className="col-auto">
                  <Dropdown drop="down">
                    <Dropdown.Toggle as={ThreeDotToggle}></Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        as="button"
                        onClick={() =>
                          navigateToEditEmployee(props.employee.id)
                        }
                      >
                        <FontAwesomeIcon
                          style={{ marginRight: 10 }}
                          icon={faPenToSquare}
                        />
                        Bearbeiten
                      </Dropdown.Item>
                      <Dropdown.Divider />
                      <Dropdown.Item
                        as="button"
                        onClick={() =>
                          setConfirmDeleteModalState({
                            open: true,
                            itemId: props.employee.id,
                          })
                        }
                      >
                        <FontAwesomeIcon
                          style={{ marginRight: 10 }}
                          icon={faTrash}
                        />
                        Löschen
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Col>
              )}
            </Row>
            <Subline>Allgemein</Subline>
            <List data={listElementsGeneral}></List>
            <Subline className="mt-5">Profil</Subline>
            <List data={listElementsProfile}></List>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={3}>
          <DashboardCard
            subline="Proben Gesamt"
            headline={String(props.employee.totalSalesSuccess)}
          />
        </Col>
        <Col xs={12} md={3}>
          <DashboardCard
            subline="Einsätze Gesamt"
            headline={String(props.employee.totalMissionsCount)}
          />
        </Col>
        <Col xs={12} md={3}>
          <DashboardCard
            subline="Vertriebszeit Gesamt"
            headline={formatHours(props.employee.totalSalesDuration)}
          />
        </Col>
        <Col xs={12} md={3}>
          <DashboardCard
            subline="Schnitt"
            headline={String(props.employee.salesSuccessPerHour)}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={3}>
          <DashboardCard
            subline="Proben %"
            headline={
              roundToDecimals(props.employee.percentageSalesSuccess * 100, 2) +
              "%"
            }
          />
        </Col>
        <Col xs={12} md={3}>
          <DashboardCard
            subline="Kein Bedarf %"
            headline={
              roundToDecimals(props.employee.percentageSalesFailed * 100, 2) +
              "%"
            }
          />
        </Col>
        <Col xs={12} md={3}>
          <DashboardCard
            subline="Nicht Angetroffen %"
            headline={
              roundToDecimals(
                props.employee.percentageSalesNotAvailable * 100,
                2
              ) + "%"
            }
          />
        </Col>
        <Col xs={12} md={3}>
          <Card>
            <Subline>Stimmung</Subline>
            <EmojiBig rating={props.employee.avgRating}></EmojiBig>
          </Card>
        </Col>
      </Row>
      {isAdmin(store.accessTokenPayload) && (
        <Row>
          <Col>
            <Card>
              <Subline>Zahlung</Subline>
              <List data={listElementsPayment}></List>
              <Subline className="mt-5">Vertrag</Subline>
              <List data={listElementsContract}></List>
            </Card>
          </Col>
        </Row>
      )}
      <ConfirmDeleteModal
        open={confirmDeleteModalState.open}
        nameInText={"den Mitarbeiter"}
        nameInHeadline={"Mitarbeiter"}
        onSuccess={() => onDeleteEmployee(confirmDeleteModalState.itemId)}
        onAbort={() =>
          setConfirmDeleteModalState({ open: false, itemId: undefined })
        }
      ></ConfirmDeleteModal>
    </>
  );
};

export default EmployeeDetail;
