import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Feature } from "ol";
import { Geometry, Polygon } from "ol/geom";
import { DrawEvent } from "ol/interaction/Draw";
import VectorSource from "ol/source/Vector";
import { FC, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useAreaActions } from "../../actions/areaActions";
import { useOrganizationActions } from "../../actions/organizationActions";
import AreaList from "../../areas/components/AreasCardList";
import CreateAreaModal from "../components/AreaCreateModal";
import CreateMissionModal from "../components/MissionCreateModal";
import MapContainer from "../components/MapContainer";
import Button from "../../shared/ui/Button";
import Headline from "../../shared/ui/Headline";
import UpdateAreaModal from "../components/AreaEditModal";
import { IArea, useStore } from "../../store";
import { getActiveUser } from "../../utils/activeUser";
import { isActivePromoter } from "../../utils/isActivePromoter";
import { isAdminOrTeamlead } from "../../utils/isRole";
import FullContainer from "../../shared/ui/FullContainer";
import { scroller } from "react-scroll";

interface CreateModalState {
  open: boolean;
  polygon?: any[];
}

interface UpdateModalState {
  open: boolean;
  area?: IArea;
}

interface CreateMissionModalState {
  open: boolean;
  area?: IArea;
}

const Map: FC = () => {
  const [store, setStore] = useStore();

  const [createModalState, setCreateModalState] = useState<CreateModalState>({
    open: false,
  });

  const [updateModalState, setUpdateModalState] = useState<UpdateModalState>({
    open: false,
  });

  const [latestFeature, setLatestFeature] = useState<{
    vectorSource: VectorSource;
    feature: Feature<Geometry> | undefined;
  }>();

  const [createMissionModalState, setCreateMissionModalState] =
    useState<CreateMissionModalState>({
      open: false,
    });

  const [scrollArea, setScrollArea] = useState<string>();

  const { getAllAreas, updateArea } = useAreaActions();
  const { getUsersOrganization } = useOrganizationActions();

  useEffect(() => {
    getUsersOrganization();
    getAllAreas();
  }, []);

  useEffect(() => {
    if (!zoomArea?.areaId) {
      const areaId = isAdminOrTeamlead(store.accessTokenPayload)
        ? store.areas[0]?.id
        : store.areas.filter((area: IArea) =>
            isActivePromoter(area, getActiveUser(store))
          )[0]?.id;

      setZoomArea({
        areaId: areaId,
        updateKey: Math.random(),
      });
      setScrollArea(areaId);
    }
  }, [store.areas]);

  useEffect(() => {
    if (scrollArea) {
      scroller.scrollTo(scrollArea, {
        duration: 300,
        delay: 0,
        smooth: "easeInOutQuart",
        containerId: "areas-container",
      });
    }
  }, [scrollArea]);

  // We have to hack the update key so it always updates useEffect
  const [zoomArea, setZoomArea] = useState<{
    areaId: string;
    updateKey: number;
  }>();

  const [drawing, setDrawing] = useState<boolean>(false);
  const [editing, setEditing] = useState<boolean>(false);

  const onNewAreaClick = () => {
    setDrawing(true);
  };

  const onCreatedAreaSuccess = (area: IArea) => {
    setCreateModalState({
      open: false,
    });
    onAreaClick(area.id);
  };

  const onCreatedAreaAbort = () => {
    if (latestFeature && latestFeature.feature) {
      latestFeature?.vectorSource.removeFeature(latestFeature.feature);
    }
    setCreateModalState({
      open: false,
      polygon: undefined,
    });
  };

  const onUpdatedAreaSuccess = () => {
    setUpdateModalState({
      open: false,
    });
  };

  const onUpdatedAreaAbort = () => {
    setUpdateModalState({
      open: false,
      area: undefined,
    });
  };

  const onFinishedPromotionSuccess = () => {
    setCreateMissionModalState({
      open: false,
    });
  };

  const onFinishedPromotionAbort = () => {
    setCreateMissionModalState({
      open: false,
      area: undefined,
    });
  };

  const onAreaClick = (areaId: string) => {
    setZoomArea({ areaId: areaId, updateKey: Math.random() });
    setScrollArea(areaId);
  };

  const onFinishArea = async (areaId: string) => {
    const now = new Date();
    now.setTime(now.getTime() + new Date().getTimezoneOffset() * 60 * 1000);

    const updateDto = {
      completedAt: now.toISOString(),
      promoters: [],
    };
    await updateArea(areaId, updateDto);
    await getAllAreas();
  };

  const onDrawEnd = (event: DrawEvent) => {
    setDrawing(false);
    const polygon = event.feature.getGeometry() as Polygon;

    const coords = polygon
      .getCoordinates()[0]
      .map((c) => [String(c[0]), String(c[1])]);

    setCreateModalState({
      open: true,
      polygon: coords,
    });
  };

  const onNewFeature = (
    vectorSource: VectorSource,
    feature: Feature<Geometry> | undefined
  ) => {
    setLatestFeature({
      vectorSource,
      feature,
    });
  };

  if (!store.areas || !store.organization?.employees) {
    return (
      <FullContainer>
        <Headline>Laden ...</Headline>
      </FullContainer>
    );
  }

  return (
    <>
      <MapContainer
        areas={store.areas}
        zoomArea={zoomArea}
        selectedArea={scrollArea}
        onDrawEnd={onDrawEnd}
        drawing={drawing}
        editing={editing}
        onNewFeature={onNewFeature}
        onAreaClick={onAreaClick}
      >
        <Row>
          <Col>
            <Headline>Gebiete</Headline>
          </Col>
          {isAdminOrTeamlead(store.accessTokenPayload) && (
            <Col className="col-auto">
              <Button onClick={onNewAreaClick} disabled={drawing}>
                <FontAwesomeIcon icon={faPlus} style={{ marginRight: 10 }} />{" "}
                Gebiet erstellen
              </Button>
            </Col>
          )}
        </Row>
        <Row>
          <AreaList
            areas={store.areas}
            onView={(area: IArea) => {
              setZoomArea({ areaId: area.id, updateKey: Math.random() });
              setScrollArea(area.id);
            }}
            onEdit={(area: IArea) => {
              setUpdateModalState({
                open: true,
                area: area,
              });
            }}
            onNewMission={(area: IArea) => {
              setCreateMissionModalState({
                open: true,
                area: area,
              });
            }}
            onFinish={(area: IArea) => {
              onFinishArea(area.id);
            }}
            activeArea={scrollArea}
          />
        </Row>
      </MapContainer>
      <CreateAreaModal
        open={createModalState.open}
        polygon={createModalState.polygon}
        onSuccess={onCreatedAreaSuccess}
        onAbort={onCreatedAreaAbort}
      />
      <UpdateAreaModal
        open={updateModalState.open}
        area={updateModalState.area}
        onSuccess={onUpdatedAreaSuccess}
        onAbort={onUpdatedAreaAbort}
      />
      <CreateMissionModal
        open={createMissionModalState.open}
        area={createMissionModalState.area}
        onSuccess={onFinishedPromotionSuccess}
        onAbort={onFinishedPromotionAbort}
      />
    </>
  );
};

export default Map;
