import { FC, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useMissionActions } from "../../actions/missionActions";
import { useOrganizationActions } from "../../actions/organizationActions";
import { IArea, IMission, IUser, useStore } from "../../store";
import { dateForDateTimeInputValue } from "../../utils/dateForDateTimeInputValue";
import EmojiRating from "../../shared/form/EmojiRating";
import InputField from "../../shared/form/InputField";
import Select from "../../shared/form/Select";
import Textarea from "../../shared/form/Textarea";
import ToggableInputField from "../../shared/form/ToggableInputField";
import Button from "../../shared/ui/Button";
import FileInputField from "../../shared/form/FileInputField";

type UpdateMissionForm = {
  salesStart: string;
  salesEnd: string;
  salesSuccess: number;
  salesNotAvailable: number;
  salesFailed: number;
  drivingTime?: number;
  finisher: string;
  rating: number;
  comment: string;
  files: FileList;
  deletedFiles: string[];
};

interface MissionEditFormProps {
  mission: IMission;
  areas: IArea[];
  employees: IUser[];
}

const MissionEditForm: FC<MissionEditFormProps> = (
  props: MissionEditFormProps
) => {
  const [store] = useStore();
  const [loading, setLoading] = useState<boolean>(false);
  const { getUsersOrganization } = useOrganizationActions();
  const navigate = useNavigate();
  const methods = useForm<UpdateMissionForm>({
    defaultValues: {
      salesStart: dateForDateTimeInputValue(props.mission.salesStart),
      salesEnd: dateForDateTimeInputValue(props.mission.salesEnd),
      salesSuccess: props.mission.salesSuccess,
      salesNotAvailable: props.mission.salesNotAvailable,
      salesFailed: props.mission.salesFailed,
      drivingTime: props.mission.drivingTime,
      finisher: props.mission.finisher.id,
      rating: props.mission.rating,
      comment: props.mission.comment,
      deletedFiles: [],
    },
  });

  const { updateMission } = useMissionActions();
  const onSubmit = async (data: UpdateMissionForm) => {
    const missionId = props.mission.id;
    if (missionId) {
      setLoading(true);
      await updateMission(
        missionId,
        {
          salesStart: new Date(data.salesStart).toISOString(),
          salesEnd: new Date(data.salesEnd).toISOString(),
          salesSuccess: data.salesSuccess,
          salesNotAvailable: data.salesNotAvailable,
          salesFailed: data.salesFailed,
          drivingTime: data.drivingTime,
          finisher: { id: data.finisher },
          rating: data.rating,
          comment: data.comment,
        },
        data.files,
        data.deletedFiles
      );
      setLoading(false);
      navigateToMissions();
    }
  };

  const navigateToMissions = () => {
    navigate("/missions");
  };

  useEffect(() => {
    getUsersOrganization();
  }, []);

  const employees = store.organization?.employees;

  return (
    <FormProvider {...methods}>
      <Form onSubmit={methods.handleSubmit(onSubmit)}>
        <InputField
          label="Vertriebszeit Start"
          registerName="salesStart"
          type="datetime-local"
          required
        ></InputField>
        <InputField
          label="Vertriebszeit Ende"
          registerName="salesEnd"
          type="datetime-local"
          required
        ></InputField>
        <ToggableInputField
          label="Fahrzeit"
          registerName="drivingTime"
          type="number"
          defaultOpen={
            props.mission.drivingTime !== undefined &&
            props.mission.drivingTime !== 0
          }
        />
        <InputField
          label="Proben (PRO)"
          registerName="salesSuccess"
          type="number"
          required
        ></InputField>
        <InputField
          label="Nicht angetroffen (NA)"
          registerName="salesNotAvailable"
          type="number"
          required
        ></InputField>
        <InputField
          label="Kein Bedarf (KB)"
          registerName="salesFailed"
          type="number"
          required
        ></InputField>
        <EmojiRating label="Stimmung" registerName="rating"></EmojiRating>
        <Select label="Abgeschlossen von" registerName="finisher" required>
          {employees?.map((employee: IUser) => (
            <option value={employee.id}>
              {employee.firstname} {employee.lastname}
            </option>
          ))}
        </Select>
        <Textarea label="Kommentar" registerName="comment"></Textarea>
        <FileInputField
          label="Dateien"
          registerName="files"
          registerNameDeletedFiles="deletedFiles"
          alreadyUploadedFiles={props.mission.documents}
        ></FileInputField>
        <Form.Group>
          <Button
            variant="secondary"
            type="button"
            onClick={() => navigateToMissions()}
          >
            Abbrechen
          </Button>
          <Button
            variant="primary"
            type="submit"
            style={{ marginLeft: "10px" }}
            loading={loading}
          >
            Speichern
          </Button>
        </Form.Group>
      </Form>
    </FormProvider>
  );
};

export default MissionEditForm;
