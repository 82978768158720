import { FC, ReactNode } from "react";
import { Form } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import { ErrorTypes } from "./ErrorTypes";

interface SelectProps {
  registerName: string;
  label: string;
  type?: string;
  required?: boolean;
  errorMessages?: ErrorTypes;
  children?: ReactNode;
}

const Select: FC<SelectProps> = (props: SelectProps) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <Form.Group className="mb-3">
      <Form.Label className="fw-bold">
        <h5>{props.label + (props.required ? " *" : "")}</h5>
      </Form.Label>
      <Form.Control
        as="select"
        {...register(props.registerName, {
          required: props.required ? "Bitte wählen Sie eins aus." : false,
        })}
        isInvalid={errors[props.registerName] ? true : false}
      >
        {props.children}
      </Form.Control>
      <Form.Control.Feedback type="invalid">
        <>{errors[props.registerName]?.message}</>
      </Form.Control.Feedback>
    </Form.Group>
  );
};

export default Select;
