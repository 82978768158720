import { useState, useEffect, useRef, FC, ReactNode, useMemo } from "react";
import { Collection, Feature, Map, View } from "ol";
import TileLayer from "ol/layer/Tile";
import OSM from "ol/source/OSM";
import "ol/ol.css";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import {
  defaults,
  DragRotateAndZoom,
  Draw,
  Modify,
  Snap,
} from "ol/interaction";
import { Geometry, Polygon, SimpleGeometry } from "ol/geom";
import { DrawEvent } from "ol/interaction/Draw";
import { IArea, useStore } from "../../store";
import { isActivePromoter } from "../../utils/isActivePromoter";
import { getActiveUser } from "../../utils/activeUser";
import { isPromoter } from "../../utils/isRole";
import html2canvas from "html2canvas";
import fs from "file-saver";
import Button from "../../shared/ui/Button";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileArrowDown } from "@fortawesome/free-solid-svg-icons";
import { generateStyleForFeature } from "../../utils/generateStyleForFeature";
import { FeatureLike } from "ol/Feature";
import { Element as ScrollElement } from "react-scroll";

const exportPNG = (map: Map, callback: () => void): void => {
  map.once("rendercomplete", function () {
    html2canvas(map.getViewport(), {
      allowTaint: true,
      useCORS: true,
      ignoreElements: (element: Element): boolean => {
        return (
          element.classList.contains("ol-control") ||
          element.classList.contains("controlMenu")
        );
      },
    }).then((canvas) => {
      canvas.toBlob((blob) => {
        if (blob) {
          fs.saveAs(blob, "map.png");
          callback();
        }
      });
    });
  });
  map.renderSync();
};

interface MapContainerProps {
  drawing: boolean;
  editing: boolean;
  onDrawEnd: (event: DrawEvent) => void;
  areas: IArea[];
  zoomArea?: { updateKey: number; areaId: string };
  selectedArea?: string;
  children?: ReactNode;
  onNewFeature: (
    vectorSource: VectorSource,
    feature: Feature<Geometry> | undefined
  ) => void;
  onAreaClick: (areaId: string) => void;
}

const MapContainer: FC<MapContainerProps> = (props: MapContainerProps) => {
  const [store, _] = useStore();
  const [map, setMap] = useState<Map>();
  const [vector, setVector] = useState<VectorLayer<VectorSource<Geometry>>>();
  const [view, setView] = useState<View>();
  const [initialZoom, setInitialZoom] = useState<boolean>(false);

  const [drawHandler, setDrawHandler] = useState<Draw>();
  const [modifyHandler, setModifyHandler] = useState<Modify>();
  const mapElement = useRef<HTMLDivElement>(null);
  const mapRef = useRef<Map>();
  mapRef.current = map;

  const drawAreasOnMap = (areas: IArea[]) => {
    if (vector) {
      const source = vector.getSource();
      if (source) {
        const allFeatures = source.getFeatures();
        for (let feature of allFeatures) {
          source.removeFeature(feature);
        }
        const initialFeatures = areas.map((area: IArea) => {
          const feature = new Feature(
            new Polygon([
              area.polygon.map((p: string[]) => [Number(p[0]), Number(p[1])]),
            ])
          );
          feature.set("id", area.id);

          const style = generateStyleForFeature(area);
          feature.setStyle(style);
          return feature;
        });
        source.addFeatures(initialFeatures);
      }
    }

    if (map) {
      let previousHighlight: Feature | null = null;
      map.on("pointermove", (e) => {
        if (vector) {
          const source = vector.getSource();
          if (source) {
            if (previousHighlight) {
              const area = props.areas.find(
                (area) => area.id === previousHighlight?.get("id")
              );
              if (area) {
                previousHighlight.setStyle(
                  generateStyleForFeature(area, false)
                );
              }
            }
            previousHighlight = null;
          }
          map.forEachFeatureAtPixel(e.pixel, (f: FeatureLike) => {
            if (f instanceof Feature) {
              const area = props.areas.find((area) => area.id === f.get("id"));
              if (area) {
                f.setStyle(generateStyleForFeature(area, true));
                previousHighlight = f;
              }
              return true;
            } else {
              return false;
            }
          });
        }
      });

      map.on("click", (e) => {
        map.forEachFeatureAtPixel(e.pixel, (f: FeatureLike, layer) => {
          if (f instanceof Feature) {
            const area = props.areas.find((area) => area.id === f.get("id"));
            if (area) {
              props.onAreaClick(area.id);
            }
            return true;
          } else {
            return false;
          }
        });
      });
    }
  };

  const onExport = () => {
    if (map) {
      exportPNG(map, () => {
        toast("Die Karte wurde erfolgreich exportiert.", {
          type: "success",
          position: "top-right",
        });
      });
    }
  };

  useEffect(() => {
    if (mapElement.current) {
      const vectorSource = new VectorSource();
      vectorSource.on("addfeature", (event) => {
        props.onNewFeature(vectorSource, event.feature);
      });

      vectorSource.on("removefeature", (event) => {
        //console.log(event);
      });

      vectorSource.on("changefeature", (event) => {
        //console.log(event);
      });

      const vector = new VectorLayer({
        source: vectorSource,
        style: {
          "fill-color": "rgba(255, 255, 255, 0.2)",
          "stroke-color": "#ffcc33",
          "stroke-width": 2,
          "circle-radius": 7,
          "circle-fill-color": "#ffcc33",
        },
      });
      setVector(vector);
      const view = new View({
        center: [0, 0],
        zoom: 0,
      });
      const initialMap = new Map({
        interactions: defaults().extend([new DragRotateAndZoom()]),
        target: mapElement.current,
        layers: [
          new TileLayer({
            source: new OSM(),
          }),
          vector,
        ],
        view: view,
      });
      setView(view);

      const source = vector.getSource();
      if (source) {
        const draw = new Draw({
          source: source,
          type: "Polygon",
        });

        draw.on("drawend", (event) => {
          props.onDrawEnd(event);
        });

        setDrawHandler(draw);
        initialMap.addInteraction(draw);
        draw.setActive(false);

        const modify = new Modify({
          features: new Collection(source.getFeatures()),
        });
        initialMap.addInteraction(modify);
        modify.setActive(false);
        setModifyHandler(modify);

        const snap = new Snap({
          source: source,
        });
        initialMap.addInteraction(snap);
      }
      setMap(initialMap);
    }

    return () => {
      setMap(undefined);
      setVector(undefined);
      setView(undefined);
    };
  }, []);

  useEffect(() => {
    if (props.zoomArea && props.zoomArea.areaId) {
      zoomToArea(props.zoomArea.areaId);
    }
  }, [props.zoomArea, map]);

  useEffect(() => {
    if (props.areas) {
      if (isPromoter(store.accessTokenPayload)) {
        drawAreasOnMap(
          props.areas.filter((area: IArea) =>
            isActivePromoter(area, getActiveUser(store))
          )
        );
      } else {
        drawAreasOnMap(props.areas);
      }
    }
    if (props.zoomArea && props.zoomArea.areaId && !initialZoom) {
      zoomToArea(props.zoomArea.areaId);
      setInitialZoom(true);
    }
  }, [props.areas]);

  useEffect(() => {
    drawHandler?.setActive(props.drawing);
  }, [props.drawing]);

  useEffect(() => {
    modifyHandler?.setActive(props.editing);
  }, [props.editing]);

  const zoomToArea = (areaId: string) => {
    if (vector && view) {
      const source = vector.getSource();
      if (source) {
        const features = source.getFeatures();
        let findFeature;
        for (let feature of features) {
          if (feature.get("id") === areaId) {
            findFeature = feature;
          }
        }
        if (findFeature) {
          const polygon = findFeature.getGeometry() as SimpleGeometry;
          if (polygon) {
            view.fit(polygon, { padding: [50, 50, 50, 50] });
          }
        }
      }
    }
  };

  return (
    <div style={{ height: "calc(100% - 56px)", display: "flex" }}>
      <ScrollElement
        name="areas-container"
        id="areas-container"
        style={{
          height: "100%",
          width: "570px",
          overflow: "auto",
          padding: 40,
        }}
      >
        {props.children}
      </ScrollElement>
      <div
        style={{
          height: "100%",
          width: "100%",
          position: "relative",
        }}
        ref={mapElement}
        className="map-container"
      >
        <Button
          onClick={() => onExport()}
          style={{
            position: "absolute",
            bottom: 20,
            left: 20,
            zIndex: 100,
          }}
          variant="secondary"
        >
          <FontAwesomeIcon icon={faFileArrowDown} style={{ marginRight: 10 }} />
          Karte speichern
        </Button>
      </div>
    </div>
  );
};

export default MapContainer;
