import { FC, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAreaActions } from "../../actions/areaActions";
import Mission from "../components/MissionDetail";
import { IArea, IMission, useStore } from "../../store";
import FullContainer from "../../shared/ui/FullContainer";

const MissionDetail: FC = () => {
  const [store, _] = useStore();
  let { id } = useParams();
  const { getAllAreas } = useAreaActions();

  useEffect(() => {
    getAllAreas();
  }, []);

  let mission: IMission | undefined = undefined;
  let area: IArea | undefined = undefined;
  for (let a of store.areas) {
    for (let m of a.missions) {
      if (m.id === id) {
        mission = m;
        area = a;
      }
    }
  }

  if (!store.areas || store.areas.length === 0) {
    return <FullContainer>Laden...</FullContainer>;
  }

  if (!mission || !area) {
    return <FullContainer>Einsatz nicht gefunden</FullContainer>;
  }

  return (
    <FullContainer>
      <Mission mission={mission} area={area}></Mission>
    </FullContainer>
  );
};

export default MissionDetail;
