import {
  faCheckSquare,
  faEllipsis,
  faEye,
  faLock,
  faLockOpen,
  faPenToSquare,
  faSort,
  faSortDown,
  faSortUp,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import { FC, useState } from "react";
import { Dropdown, Table as BTable } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useEmployeeActions } from "../../actions/employeeActions";
import { IUser } from "../../store";
import { formatDate } from "../../utils/formatDate";
import ConfirmDeleteModal, {
  ConfirmDeleteModalState,
} from "../../shared/ui/ConfirmDeleteModal";
import { EmployeeStatusBadge } from "./EmployeeStatusBadge";
import EmptyList from "../../shared/ui/EmptyList";
import ThreeDotToggle from "../../shared/ui/ThreeDotToggle";

interface EmployeesTableProps {
  data: IUser[];
}

const EmployeesTable: FC<EmployeesTableProps> = (
  props: EmployeesTableProps
) => {
  const { deActivateEmployee, deleteEmployee } = useEmployeeActions();
  const [sorting, setSorting] = useState<SortingState>([]);
  const columnHelper = createColumnHelper<IUser>();
  const columns = [
    columnHelper.accessor("alternativeId", {
      header: "Repräsentantennummer",
    }),
    columnHelper.accessor("firstname", {
      header: "Vorname",
    }),
    columnHelper.accessor("lastname", {
      header: "Nachname",
    }),
    columnHelper.accessor("enteredAt", {
      header: "Eintrittsdatum",
      cell: (props) =>
        props.getValue() ? formatDate(props.getValue() as string) : "/",
    }),
    columnHelper.accessor("active", {
      header: "Status",
      cell: (props) => <EmployeeStatusBadge active={props.getValue()} />,
    }),
  ];

  const [confirmDeleteModalState, setConfirmDeleteModalState] =
    useState<ConfirmDeleteModalState>({
      open: false,
    });

  const table = useReactTable<IUser>({
    data: props.data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  const navigate = useNavigate();

  if (props.data.length === 0) {
    return <EmptyList name="Mitarbeiter" />;
  }

  const navigateToEmployee = (id: string) => {
    navigate("/employees/" + id);
  };

  const navigateToEditEmployee = (id: string) => {
    navigate("/employees/" + id + "/edit");
  };

  const onDeleteEmployee = async (id?: string) => {
    if (id) {
      await deleteEmployee(id);
      setConfirmDeleteModalState({
        open: false,
        itemId: undefined,
      });
    }
  };

  return (
    <>
      <BTable hover>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <th key={header.id} colSpan={header.colSpan}>
                    {header.isPlaceholder ? null : (
                      <div
                        {...{
                          role: header.column.getCanSort() ? "button" : "",
                          onClick: header.column.getToggleSortingHandler(),
                        }}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {{
                          asc: (
                            <FontAwesomeIcon icon={faSortUp}></FontAwesomeIcon>
                          ),
                          desc: (
                            <FontAwesomeIcon
                              icon={faSortDown}
                            ></FontAwesomeIcon>
                          ),
                        }[header.column.getIsSorted() as string] ?? (
                          <FontAwesomeIcon
                            icon={faSort}
                            className="text-muted"
                          ></FontAwesomeIcon>
                        )}
                      </div>
                    )}
                  </th>
                );
              })}
              <th key="actions"></th>
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id} className={"cursor-hover"}>
              {row.getVisibleCells().map((cell) => (
                <td
                  key={cell.id}
                  className="align-middle"
                  onClick={() => navigateToEmployee(row.original.id)}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
              <td key={row.id + "-action"} className="align-middle">
                <Dropdown drop="down">
                  <Dropdown.Toggle as={ThreeDotToggle}></Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      as="button"
                      onClick={() => navigateToEmployee(row.original.id)}
                    >
                      <FontAwesomeIcon
                        style={{ marginRight: 10 }}
                        icon={faEye}
                      />
                      Anzeigen
                    </Dropdown.Item>
                    <Dropdown.Item
                      as="button"
                      onClick={() => navigateToEditEmployee(row.original.id)}
                    >
                      <FontAwesomeIcon
                        style={{ marginRight: 10 }}
                        icon={faPenToSquare}
                      />
                      Bearbeiten
                    </Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item
                      as="button"
                      onClick={() =>
                        deActivateEmployee(
                          row.original.id,
                          !row.original.active
                        )
                      }
                    >
                      <FontAwesomeIcon
                        style={{ marginRight: 10 }}
                        icon={row.original.active ? faLock : faLockOpen}
                      />
                      {row.original.active ? "Deaktivieren" : "Aktivieren"}
                    </Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item
                      as="button"
                      onClick={() =>
                        setConfirmDeleteModalState({
                          open: true,
                          itemId: row.original.id,
                        })
                      }
                    >
                      <FontAwesomeIcon
                        style={{ marginRight: 10 }}
                        icon={faTrash}
                      />
                      Löschen
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </td>
            </tr>
          ))}
        </tbody>
      </BTable>
      <ConfirmDeleteModal
        open={confirmDeleteModalState.open}
        nameInText={"den Mitarbeiter"}
        nameInHeadline={"Mitarbeiter"}
        onSuccess={() => onDeleteEmployee(confirmDeleteModalState.itemId)}
        onAbort={() =>
          setConfirmDeleteModalState({ open: false, itemId: undefined })
        }
      ></ConfirmDeleteModal>
    </>
  );
};

export default EmployeesTable;
