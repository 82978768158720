import { useState, useEffect, useRef, FC } from "react";
import { Feature, Map, View } from "ol";
import TileLayer from "ol/layer/Tile";
import OSM from "ol/source/OSM";
import "ol/ol.css";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import { Geometry, Polygon, SimpleGeometry } from "ol/geom";
import { IArea } from "../../store";
import Style from "ol/style/Style";
import Fill from "ol/style/Fill";
import Stroke from "ol/style/Stroke";

interface MapThumbnailProps {
  area: IArea;
}

const MapThumbnail: FC<MapThumbnailProps> = (props: MapThumbnailProps) => {
  const [map] = useState<Map>();
  const mapElement = useRef<HTMLDivElement>(null);
  const mapRef = useRef<Map>();
  mapRef.current = map;
  const [vector, setVector] = useState<VectorLayer<VectorSource<Geometry>>>();

  useEffect(() => {
    if (mapElement.current) {
      const vectorSource = new VectorSource();
      const vector = new VectorLayer({
        source: vectorSource,
        style: {
          "fill-color": "rgba(255, 255, 255, 0.2)",
          "stroke-color": "#ffcc33",
          "stroke-width": 2,
          "circle-radius": 7,
          "circle-fill-color": "#ffcc33",
        },
      });

      setVector(vector);

      const view = new View({
        center: [0, 0],
        zoom: 0,
      });

      new Map({
        target: mapElement.current,
        layers: [
          new TileLayer({
            source: new OSM(),
          }),
          vector,
        ],
        view: view,
        interactions: [],
        controls: [],
      });

      const feature = new Feature(
        new Polygon([
          props.area.polygon.map((p: string[]) => [Number(p[0]), Number(p[1])]),
        ])
      );
      feature.set("id", props.area.id);

      let fillColor = "rgba(0,255,0,0.2)";
      let strokeColor = "rgba(0,255,0,0.8)";

      if (props.area.status === "blocked") {
        fillColor = "rgba(0,0,0,0.2)";
        strokeColor = "rgba(0,0,0,0.4)";
      } else if (props.area.status === "completed") {
        fillColor = "rgba(255,0,0,0.2)";
        strokeColor = "rgba(255,0,0,0.8)";
      } else if (props.area.status === "active") {
        fillColor = "rgba(255,255,0,0.2)";
        strokeColor = "rgba(255,255,0,0.8)";
      }

      const style = new Style({
        fill: new Fill({
          color: fillColor,
        }),
        stroke: new Stroke({
          color: strokeColor,
          width: 4,
        }),
      });
      feature.setStyle(style);
      vectorSource.addFeature(feature);
      const polygon = feature.getGeometry() as SimpleGeometry;
      if (polygon) {
        view.fit(polygon, { padding: [50, 50, 50, 50] });
      }
    }
  }, []);

  useEffect(() => {
    if (vector) {
      const source = vector.getSource();
      if (source) {
        const features = source.getFeatures();
        for (let feature of features) {
          let fillColor = "rgba(0,255,0,0.2)";
          let strokeColor = "rgba(0,255,0,0.8)";

          if (props.area.status === "blocked") {
            fillColor = "rgba(0,0,0,0.2)";
            strokeColor = "rgba(0,0,0,0.4)";
          } else if (props.area.status === "completed") {
            fillColor = "rgba(255,0,0,0.2)";
            strokeColor = "rgba(255,0,0,0.8)";
          } else if (props.area.status === "active") {
            fillColor = "rgba(255,255,0,0.2)";
            strokeColor = "rgba(255,255,0,0.8)";
          }

          const style = new Style({
            fill: new Fill({
              color: fillColor,
            }),
            stroke: new Stroke({
              color: strokeColor,
              width: 4,
            }),
          });
          feature.setStyle(style);
        }
      }
    }
  }, [props.area]);

  return (
    <div
      style={{ height: "400px", width: "100%" }}
      ref={mapElement}
      className="map-container"
    />
  );
};

export default MapThumbnail;
