import { FC } from "react";

interface EmojiBigProps {
  rating: number | null;
}

const styles = {
  inactiveImage: {
    filter: "grayscale(100%)",
  },
  activeImage: {},
};

const EmojiBig: FC<EmojiBigProps> = (props: EmojiBigProps) => {
  return (
    <div className="d-flex flex-row">
      <img
        style={props.rating !== 1 ? styles.inactiveImage : styles.activeImage}
        src="https://img.icons8.com/emoji/48/000000/angry-face-emoji--v2.png"
      />
      <img
        style={props.rating !== 2 ? styles.inactiveImage : styles.activeImage}
        src="https://img.icons8.com/fluent/48/000000/sad.png"
      />
      <img
        style={props.rating !== 3 ? styles.inactiveImage : styles.activeImage}
        src="https://img.icons8.com/color/48/000000/happy.png"
      />
      <img
        style={props.rating !== 4 ? styles.inactiveImage : styles.activeImage}
        src="https://img.icons8.com/emoji/48/000000/smiling-face.png"
      />
      <img
        style={props.rating !== 5 ? styles.inactiveImage : styles.activeImage}
        src="https://img.icons8.com/color/48/000000/lol.png"
      />
    </div>
  );
};

export default EmojiBig;
