import { FC } from "react";
import CreateEmployeeForm from "../components/EmployeeCreateForm";
import Card from "../../shared/ui/Card";
import Headline from "../../shared/ui/Headline";
import FullContainer from "../../shared/ui/FullContainer";

const EmployeeCreate: FC = () => {
  return (
    <FullContainer>
      <Card>
        <Headline>Mitarbeiter erstellen</Headline>
        <CreateEmployeeForm />
      </Card>
    </FullContainer>
  );
};

export default EmployeeCreate;
