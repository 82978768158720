import { FC } from "react";
import { Modal, ModalTitle } from "react-bootstrap";
import Button from "./Button";
import Subline from "./Subline";

interface ConfirmDeleteModalProps {
  open: boolean;
  nameInHeadline: string;
  nameInText: string;
  onSuccess: () => void;
  onAbort: () => void;
}

const ConfirmDeleteModal: FC<ConfirmDeleteModalProps> = (
  props: ConfirmDeleteModalProps
) => {
  return (
    <Modal
      centered
      show={props.open}
      backdrop="static"
      keyboard={false}
      onHide={props.onAbort}
    >
      <Modal.Header closeButton>
        <ModalTitle className="fw-bold">
          {props.nameInHeadline} löschen
        </ModalTitle>
      </Modal.Header>
      <Modal.Body>
        <Subline>Möchten Sie {props.nameInText} wirklich löschen?</Subline>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.onAbort}>
          Abbrechen
        </Button>
        <Button variant="danger" onClick={props.onSuccess}>
          Löschen
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export interface ConfirmDeleteModalState {
  open: boolean;
  itemId?: string;
}

export default ConfirmDeleteModal;
