import {
  faCheckSquare,
  faEye,
  faLock,
  faLockOpen,
  faPenToSquare,
  faSort,
  faSortDown,
  faSortUp,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import { FC, useState } from "react";
import { Col, Dropdown, Row, Table as BTable } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useAreaActions } from "../../actions/areaActions";
import { IArea, IUser } from "../../store";
import { formatDate } from "../../utils/formatDate";
import { fuzzyFilter } from "../../utils/fuzzyFilter";
import { mapStatus } from "../../utils/mapStatus";
import { AreaStatusBadge } from "./AreaStatusBadge";
import ConfirmDeleteModal, {
  ConfirmDeleteModalState,
} from "../../shared/ui/ConfirmDeleteModal";
import EmptyList from "../../shared/ui/EmptyList";
import { DebouncedInput } from "../../shared/ui/DebouncedInput";
import ThreeDotToggle from "../../shared/ui/ThreeDotToggle";

interface AreasTableProps {
  data: IArea[];
}

const AreasTable: FC<AreasTableProps> = (props: AreasTableProps) => {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [globalFilter, setGlobalFilter] = useState<string>("");

  const { deleteArea, blockArea, completeArea } = useAreaActions();
  const columnHelper = createColumnHelper<IArea>();
  const columns = [
    columnHelper.accessor(
      (row) => row.name + " " + row.city + " " + row.zipCode,
      {
        header: "Name",
        cell: (props) => (
          <div className="d-inline-flex flex-column p-2">
            <span className="fw-bold">{props.row.original.name}</span>
            <span>
              {props.row.original.city +
                " (" +
                props.row.original.zipCode +
                ")"}
            </span>
          </div>
        ),
      }
    ),
    columnHelper.accessor(
      (row) =>
        row.promoters
          .map(
            (promoter: IUser) => promoter.firstname + " " + promoter.lastname
          )
          .join(", "),
      {
        header: "Aktive Promoter",
        cell: (props) =>
          props.row.original.promoters
            ? props.row.original.promoters
                .map(
                  (promoter: IUser) =>
                    promoter.firstname + " " + promoter.lastname
                )
                .join(", ")
            : "/",
      }
    ),
    columnHelper.accessor("totalSalesSuccess", {
      header: "Proben",
    }),
    columnHelper.accessor("salesSuccessPerHour", {
      header: "Schnitt",
    }),
    columnHelper.accessor("completedAt", {
      header: "Zuletzt abgeschlossen",
      cell: (props) => (props.getValue() ? formatDate(props.getValue()) : "/"),
    }),
    columnHelper.accessor((row) => mapStatus(row.status), {
      header: "Status",
      cell: (props) => <AreaStatusBadge status={props.row.original.status} />,
    }),
  ];

  const [confirmDeleteModalState, setConfirmDeleteModalState] =
    useState<ConfirmDeleteModalState>({
      open: false,
    });

  const table = useReactTable<IArea>({
    data: props.data,
    columns,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    state: {
      sorting,
      globalFilter,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
  });

  const navigate = useNavigate();

  if (props.data.length === 0) {
    return <EmptyList name="Gebiete" />;
  }

  const navigateToArea = (id: string) => {
    navigate("/areas/" + id);
  };

  const navigateToEditArea = (id: string) => {
    navigate("/areas/" + id + "/edit");
  };

  const onDeleteArea = async (id?: string) => {
    if (id) {
      await deleteArea(id);
      setConfirmDeleteModalState({
        open: false,
        itemId: undefined,
      });
    }
  };

  return (
    <>
      <Row>
        <Col xs={12} lg={4}>
          <DebouncedInput
            value={globalFilter ?? ""}
            onChange={(value: string | number) =>
              setGlobalFilter(String(value))
            }
            placeholder="Suchen ..."
          />
        </Col>
      </Row>

      <BTable hover responsive>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <th key={header.id} colSpan={header.colSpan}>
                    {header.isPlaceholder ? null : (
                      <div
                        {...{
                          role: header.column.getCanSort() ? "button" : "",
                          onClick: header.column.getToggleSortingHandler(),
                        }}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {{
                          asc: (
                            <FontAwesomeIcon icon={faSortUp}></FontAwesomeIcon>
                          ),
                          desc: (
                            <FontAwesomeIcon
                              icon={faSortDown}
                            ></FontAwesomeIcon>
                          ),
                        }[header.column.getIsSorted() as string] ?? (
                          <FontAwesomeIcon
                            icon={faSort}
                            className="text-muted"
                          ></FontAwesomeIcon>
                        )}
                      </div>
                    )}
                  </th>
                );
              })}
              <th key="actions"></th>
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id} className={"cursor-hover"}>
              {row.getVisibleCells().map((cell) => (
                <td
                  key={cell.id}
                  className="align-middle"
                  onClick={() => navigateToArea(row.original.id)}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
              <td key={row.id + "-action"} className="align-middle">
                <Dropdown drop="down">
                  <Dropdown.Toggle as={ThreeDotToggle}></Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      as="button"
                      onClick={() => navigateToArea(row.original.id)}
                    >
                      <FontAwesomeIcon
                        style={{ marginRight: 10 }}
                        icon={faEye}
                      />
                      Anzeigen
                    </Dropdown.Item>
                    <Dropdown.Item
                      as="button"
                      onClick={() => navigateToEditArea(row.original.id)}
                    >
                      <FontAwesomeIcon
                        style={{ marginRight: 10 }}
                        icon={faPenToSquare}
                      />
                      Bearbeiten
                    </Dropdown.Item>
                    <Dropdown.Divider />
                    {row.original.status !== "completed" &&
                      row.original.status !== "blocked" && (
                        <Dropdown.Item
                          as="button"
                          onClick={() => completeArea(row.original.id)}
                        >
                          <FontAwesomeIcon
                            style={{ marginRight: 10 }}
                            icon={faCheckSquare}
                          />
                          Abschließen
                        </Dropdown.Item>
                      )}

                    <Dropdown.Item
                      as="button"
                      onClick={() =>
                        blockArea(
                          row.original.id,
                          row.original.isBlocked ? false : true
                        )
                      }
                    >
                      <FontAwesomeIcon
                        style={{ marginRight: 10 }}
                        icon={row.original.isBlocked ? faLockOpen : faLock}
                      />
                      {row.original.isBlocked ? "Entsperren" : "Sperren"}
                    </Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item
                      as="button"
                      onClick={() =>
                        setConfirmDeleteModalState({
                          open: true,
                          itemId: row.original.id,
                        })
                      }
                    >
                      <FontAwesomeIcon
                        style={{ marginRight: 10 }}
                        icon={faTrash}
                      />
                      Löschen
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </td>
            </tr>
          ))}
        </tbody>
      </BTable>
      <ConfirmDeleteModal
        open={confirmDeleteModalState.open}
        nameInText={"das Gebiet"}
        nameInHeadline={"Gebiet"}
        onSuccess={() => onDeleteArea(confirmDeleteModalState.itemId)}
        onAbort={() =>
          setConfirmDeleteModalState({ open: false, itemId: undefined })
        }
      ></ConfirmDeleteModal>
    </>
  );
};

export default AreasTable;
