import { FC } from "react";
import AreaCard from "./AreaCard";
import { IArea, useStore } from "../../store";
import EmptyList from "../../shared/ui/EmptyList";
import { isAdminOrTeamlead } from "../../utils/isRole";
import { getActiveUser } from "../../utils/activeUser";
import Subline from "../../shared/ui/Subline";
import { isActivePromoter } from "../../utils/isActivePromoter";

interface AreasCardListProps {
  areas: IArea[];
  activeArea?: string;
  onEdit: (area: IArea) => void;
  onView: (area: IArea) => void;
  onFinish: (area: IArea) => void;
  onNewMission: (area: IArea) => void;
}

const AreasCardList: FC<AreasCardListProps> = (props: AreasCardListProps) => {
  const [store] = useStore();

  if (props.areas.length === 0) {
    return <EmptyList name="Gebiete" />;
  }

  const notAssigned = props.areas.filter(
    (area) => area.status === "notAssigned"
  );
  const active = props.areas.filter((area) => area.status === "active");
  const completed = props.areas.filter((area) => area.status === "completed");
  const blocked = props.areas.filter((area) => area.status === "blocked");

  const userActivePromoter = active.filter((area) =>
    isActivePromoter(area, getActiveUser(store))
  );

  if (isAdminOrTeamlead(store.accessTokenPayload)) {
    return (
      <>
        <Subline>In Bearbeitung</Subline>
        {active.map((area: IArea) => {
          return (
            <AreaCard
              key={area.id}
              area={area}
              onEdit={() => props.onEdit(area)}
              onView={() => props.onView(area)}
              onFinish={() => props.onFinish(area)}
              onNewMission={() => props.onNewMission(area)}
              isActive={props.activeArea === area.id || false}
            ></AreaCard>
          );
        })}
        <Subline>Nicht zugewiesen</Subline>
        {notAssigned.map((area: IArea) => {
          return (
            <AreaCard
              key={area.id}
              area={area}
              onEdit={() => props.onEdit(area)}
              onView={() => props.onView(area)}
              onFinish={() => props.onFinish(area)}
              onNewMission={() => props.onNewMission(area)}
              isActive={props.activeArea === area.id || false}
            ></AreaCard>
          );
        })}
        <Subline>Abgeschlossen</Subline>
        {completed.map((area: IArea) => {
          return (
            <AreaCard
              key={area.id}
              area={area}
              onEdit={() => props.onEdit(area)}
              onView={() => props.onView(area)}
              onFinish={() => props.onFinish(area)}
              onNewMission={() => props.onNewMission(area)}
              isActive={props.activeArea === area.id || false}
            ></AreaCard>
          );
        })}
        <Subline>Gesperrt</Subline>
        {blocked.map((area: IArea) => {
          return (
            <AreaCard
              key={area.id}
              area={area}
              onEdit={() => props.onEdit(area)}
              onView={() => props.onView(area)}
              onFinish={() => props.onFinish(area)}
              onNewMission={() => props.onNewMission(area)}
              isActive={props.activeArea === area.id || false}
            ></AreaCard>
          );
        })}
      </>
    );
  }

  return (
    <>
      <Subline>Deine Gebiete</Subline>
      {userActivePromoter.map((area: IArea) => {
        return (
          <AreaCard
            key={area.id}
            area={area}
            onEdit={() => props.onEdit(area)}
            onView={() => props.onView(area)}
            onFinish={() => props.onFinish(area)}
            onNewMission={() => props.onNewMission(area)}
            isActive={props.activeArea === area.id || false}
          ></AreaCard>
        );
      })}
    </>
  );
};

export default AreasCardList;
