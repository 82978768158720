import { FC, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAreaActions } from "../../actions/areaActions";
import { useOrganizationActions } from "../../actions/organizationActions";
import MissionEditForm from "../components/MissionEditForm";
import Card from "../../shared/ui/Card";
import Headline from "../../shared/ui/Headline";
import { useStore } from "../../store";
import FullContainer from "../../shared/ui/FullContainer";

const MissionEdit: FC = () => {
  const [store, _] = useStore();
  let { id } = useParams();
  const { getAllAreas } = useAreaActions();
  const { getUsersOrganization } = useOrganizationActions();

  useEffect(() => {
    getAllAreas();
    getUsersOrganization();
  }, []);

  const mission = store.areas
    .flatMap((area) => area.missions)
    .find((mission) => mission.id === id);
  const employees = store.organization?.employees;

  if (
    !store.areas ||
    store.areas.length === 0 ||
    !store.organization ||
    store.organization.employees?.length === 0
  ) {
    return <FullContainer>Laden...</FullContainer>;
  }

  if (!mission || !employees) {
    return <FullContainer>Einsatz nicht gefunden.</FullContainer>;
  }

  return (
    <FullContainer>
      <Card>
        <Headline>Einsatz bearbeiten</Headline>
        <MissionEditForm
          mission={mission}
          employees={employees}
          areas={store.areas}
        ></MissionEditForm>
      </Card>
    </FullContainer>
  );
};

export default MissionEdit;
