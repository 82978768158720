import { FC, ReactNode } from "react";
import { Col, Container, Row } from "react-bootstrap";

interface FullContainerProps {
  children?: ReactNode;
}

const FullContainer: FC<FullContainerProps> = (props: FullContainerProps) => {
  return (
    <Container>
      <Row>
        <Col className="pt-4">{props.children}</Col>
      </Row>
    </Container>
  );
};

export default FullContainer;
