import { FC, useState } from "react";
import { Form } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { LogicValidationError, ValidationError } from "../../actions/api";
import { useEmployeeActions } from "../../actions/employeeActions";
import { useStore } from "../../store";
import InputField from "../../shared/form/InputField";
import Select from "../../shared/form/Select";
import Textarea from "../../shared/form/Textarea";
import Button from "../../shared/ui/Button";

type EmployeeCreateForm = {
  alternativeId: string;
  username: string;
  firstname: string;
  lastname: string;
  password: string;

  role: string;
  active: boolean;

  street?: string;
  houseNumber?: string;
  city?: string;
  zipCode?: string;
  country?: string;
  birthday?: Date;

  telephone?: string;
  email?: string;

  iban?: string;
  bic?: string;
  additionalAgreement?: string;
  hourlyRate?: number;
  commission?: number;
  paymentCurrency?: string;

  enteredAt?: Date;
  leftAt?: Date;
};

const EmployeeCreateForm: FC = () => {
  const [store, setStore] = useStore();
  const [loading, setLoading] = useState<boolean>(false);
  const { createEmployee } = useEmployeeActions();
  const navigate = useNavigate();
  const methods = useForm<EmployeeCreateForm>();
  const onSubmit = async (data: EmployeeCreateForm) => {
    setLoading(true);
    try {
      await createEmployee({
        alternativeId: data.alternativeId,
        username: data.username,
        firstname: data.firstname,
        lastname: data.lastname,
        password: data.password,
        role: data.role,
        street: data.street,
        houseNumber: data.houseNumber,
        city: data.city,
        zipCode: data.zipCode,
        country: "Germany",
        birthday: data.birthday
          ? new Date(data.birthday).toISOString()
          : undefined,
        telephone: data.telephone,
        email: data.email,
        iban: data.iban === "" ? undefined : data.iban,
        bic: data.bic === "" ? undefined : data.bic,
        additionalAgreement: data.additionalAgreement,
        hourlyRate: data.hourlyRate,
        commission: data.commission,
        paymentCurrency: "EUR",
        enteredAt: data.enteredAt && new Date(data.enteredAt).toISOString(),
      });
      navigateToEmployees();
    } catch (e) {
      if (e instanceof ValidationError || e instanceof LogicValidationError) {
        for (let fieldError of e.getFieldErrors()) {
          console.log(fieldError.fieldName);
          methods.setError(fieldError.fieldName as any, {
            message: fieldError.errorMessage,
          });
        }
      }
    }
    setLoading(false);
  };

  const navigateToEmployees = () => {
    navigate("/employees");
  };

  return (
    <FormProvider {...methods}>
      <Form onSubmit={methods.handleSubmit(onSubmit)}>
        <InputField
          label="Repräsentantennummer"
          registerName="alternativeId"
          required
        ></InputField>
        <InputField
          label="Nutzername"
          registerName="username"
          required
        ></InputField>
        <InputField
          label="Initiales Passwort"
          registerName="password"
          type="password"
          required
        ></InputField>
        <Select label="Rolle" registerName="role" required>
          <option value="admin">Admin</option>
          <option value="teamleader">Teamleiter</option>
          <option value="promoter">Promoter</option>
        </Select>
        <InputField
          label="Vorname"
          registerName="firstname"
          required
        ></InputField>
        <InputField
          label="Nachname"
          registerName="lastname"
          required
        ></InputField>
        <InputField label="Straße" registerName="street" required></InputField>
        <InputField
          label="Hausnummer"
          registerName="houseNumber"
          required
        ></InputField>
        <InputField
          label="Postleitzahl"
          registerName="zipCode"
          required
        ></InputField>
        <InputField label="Ort" registerName="city" required></InputField>
        <InputField
          label="Geburtsdatum"
          registerName="birthday"
          type="date"
          required
        ></InputField>
        <InputField
          label="Telefonnummer"
          registerName="telephone"
          required
        ></InputField>
        <InputField
          label="E-Mail"
          registerName="email"
          type="email"
          required
        ></InputField>
        <InputField label="IBAN" registerName="iban"></InputField>
        <InputField label="BIC" registerName="bic"></InputField>
        <InputField
          label="Stundenlohn"
          registerName="hourlyRate"
          type="number"
        ></InputField>
        <InputField
          label="Provision"
          registerName="commission"
          type="number"
        ></InputField>
        <Textarea
          label="Zusatzvereinbarung"
          registerName="additionalAgreement"
        ></Textarea>
        <InputField
          label="Eingestellt am"
          registerName="enteredAt"
          type="date"
          required
        ></InputField>
        <Form.Group>
          <Button
            variant="secondary"
            type="button"
            onClick={() => navigateToEmployees()}
          >
            Abbrechen
          </Button>
          <Button
            variant="primary"
            type="submit"
            style={{ marginLeft: "10px" }}
            loading={loading}
          >
            Erstellen
          </Button>
        </Form.Group>
      </Form>
    </FormProvider>
  );
};

export default EmployeeCreateForm;
