import { useStore } from "../store";
import { useApi } from "./api-factory";

export const useOrganizationActions = () => {
  const [store, setStore] = useStore();
  const { api } = useApi();

  const getUsersOrganization = async () => {
    const orgUUID = store.accessTokenPayload?.organization;
    setStore((old) => ({
      ...old,
      api: { reading: true },
    }));
    const organization = await api.get("/organization/" + orgUUID);
    setStore((old) => ({
      ...old,
      organization: organization,
      api: { reading: false },
    }));
  };

  return { getUsersOrganization };
};
