import { reset } from "ol/transform";
import { FC, useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";
import { LogicValidationError, ValidationError } from "../../actions/api";
import { useAreaActions } from "../../actions/areaActions";
import InputField from "../../shared/form/InputField";
import Button from "../../shared/ui/Button";
import { IArea } from "../../store";

interface AreaCreateModalProps {
  open: boolean;
  polygon?: any[];
  onSuccess: (area: IArea) => void;
  onAbort: () => void;
}

type CreateAreaForm = {
  name: string;
  city: string;
  zipCode: string;
};

const AreaCreateModal: FC<AreaCreateModalProps> = (
  props: AreaCreateModalProps
) => {
  const methods = useForm<CreateAreaForm>({
    defaultValues: { name: "", city: "", zipCode: "" },
  });
  const { createArea } = useAreaActions();
  const [loading, setLoading] = useState<boolean>(false);

  const onSubmit = async (data: CreateAreaForm) => {
    setLoading(true);
    try {
      if (props.polygon) {
        const area = await createArea({
          name: data.name,
          polygon: props.polygon,
          promoters: [],
          zipCode: data.zipCode,
          city: data.city,
        });
        if (area) {
          props.onSuccess(area);
        }
      }
    } catch (e) {
      if (e instanceof ValidationError || e instanceof LogicValidationError) {
        console.log("Error");
        console.log(e);
        for (let fieldError of e.getFieldErrors()) {
          methods.setError(fieldError.fieldName as any, {
            message: fieldError.errorMessage,
          });
        }
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    methods.reset();
    setLoading(false);
  }, [props.open]);

  return (
    <Modal
      show={props.open}
      onHide={props.onAbort}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Gebiet erstellen</Modal.Title>
      </Modal.Header>
      <FormProvider {...methods}>
        <Form onSubmit={methods.handleSubmit(onSubmit)}>
          <Modal.Body>
            <InputField registerName="name" label="Name" required></InputField>
            <InputField registerName="city" label="Stadt" required></InputField>
            <InputField
              registerName="zipCode"
              label="Postleitzahl"
              required
            ></InputField>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={props.onAbort}>
              Abbrechen
            </Button>
            <Button variant="primary" type="submit" loading={loading}>
              Erstellen
            </Button>
          </Modal.Footer>
        </Form>
      </FormProvider>
    </Modal>
  );
};

export default AreaCreateModal;
