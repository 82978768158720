import { FC } from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useAuthActions } from "../../actions/authActions";
import { useStore } from "../../store";
import { isAdmin } from "../../utils/isRole";
import NavigationButton from "./NavigationButton";
import { getActiveUser } from "../../utils/activeUser";
import logo from "../images/logo.png";

const Navigation: FC = () => {
  const [store] = useStore();
  const { logout } = useAuthActions();
  const navigate = useNavigate();

  return (
    <Navbar expand="lg" bg="light" variant="light" style={{ padding: 5 }}>
      <Container>
        <Navbar.Brand
          onClick={() => navigate("/")}
          className="cursor-hover"
          style={{ marginRight: 40 }}
        >
          <img src={logo} alt="Logo" height="35" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="w-100">
            {store.accessToken && (
              <div className="d-flex justify-content-between w-100">
                <div>
                  <NavigationButton path="/">Startseite</NavigationButton>
                  {isAdmin(store.accessTokenPayload) ? (
                    <>
                      <NavigationButton path="/map">Karte</NavigationButton>
                      <NavigationButton path="/areas">Gebiete</NavigationButton>
                      <NavigationButton path="/missions">
                        Einsätze
                      </NavigationButton>
                      <NavigationButton path="/employees">
                        Mitarbeiter
                      </NavigationButton>
                    </>
                  ) : (
                    <>
                      <NavigationButton path="/map">Karte</NavigationButton>
                      <NavigationButton path="/missions">
                        Einsätze
                      </NavigationButton>
                      <NavigationButton
                        path={"/employees/" + getActiveUser(store)?.id}
                      >
                        Dein Profil
                      </NavigationButton>
                    </>
                  )}
                </div>
                <Nav.Link onClick={() => logout()}>Logout</Nav.Link>
              </div>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Navigation;
