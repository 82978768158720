import { FC, useEffect, useState } from "react";
import { useApi } from "../../actions/api-factory";
import { useStore } from "../../store";
import { useLocation, useNavigate } from "react-router-dom";
import { Col, Container, Form, Row } from "react-bootstrap";
import Button from "../../shared/ui/Button";
import { useForm } from "react-hook-form";
import jwt_decode from "jwt-decode";
import Headline from "../../shared/ui/Headline";
import { toast } from "react-toastify";

interface LocationState {
  from: {
    pathname: string;
  };
}

type LoginForm = {
  username: string;
  password: string;
};

interface LoginState {
  loading: boolean;
  error?: string;
}

const Login: FC = () => {
  const [store, setStore] = useStore();
  const { api } = useApi();
  const navigate = useNavigate();
  const location = useLocation();
  const [loginState, setLoginState] = useState<LoginState>({
    loading: false,
  });

  useEffect(() => {
    if (store.accessToken) {
      navigate("/");
    }
  }, [store.accessToken, navigate]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginForm>();
  const onSubmit = async (data: LoginForm) => {
    setLoginState({
      loading: true,
    });

    try {
      const login = await api.post("auth/login", {
        username: data.username,
        password: data.password,
      });

      localStorage.setItem("accessToken", login.accessToken);
      setStore((old) => ({
        ...old,
        accessToken: login.accessToken,
        accessTokenPayload: jwt_decode(login.accessToken),
      }));

      const { from } = (location.state as LocationState) || {
        from: { pathname: "/" },
      };

      setLoginState({
        loading: false,
      });
      toast("Sie sind erfolgreich eingeloggt.", {
        type: "success",
        position: "top-right",
      });

      if (from.pathname === "/login") {
        navigate("/", { replace: true });
      } else {
        navigate(from, { replace: true });
      }
    } catch (e) {
      toast("Die Anmeldedaten sind nicht korrekt.", {
        type: "error",
        position: "top-right",
      });
      setLoginState({
        loading: false,
        error: "WRONG_CREDENTIALS",
      });
    }
  };

  return (
    <Container style={{ height: "calc(100% - 56px)" }}>
      <Row style={{ height: "100%" }}>
        <Col className="mt-4" lg="6">
          <Headline>Anmelden</Headline>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group className="mb-3">
              <Form.Label>Nutzername</Form.Label>
              <Form.Control
                type="text"
                placeholder="Nutzername"
                {...register("username", { required: true })}
                isInvalid={
                  errors.username !== undefined ||
                  loginState.error !== undefined
                }
                autoComplete="current-username"
              />
              <Form.Control.Feedback type="invalid">
                Bitte geben Sie einen validen Nutzernamen ein.
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3" controlId="passwordControl">
              <Form.Label>Passwort</Form.Label>
              <Form.Control
                type="password"
                placeholder="Passwort"
                {...register("password", { required: true })}
                isInvalid={
                  errors.password !== undefined ||
                  loginState.error !== undefined
                }
                autoComplete="current-password"
              />
              <Form.Control.Feedback type="invalid">
                Bitte geben Sie ein valides Passwort ein.
              </Form.Control.Feedback>
            </Form.Group>
            <Button
              variant="primary"
              type="submit"
              loading={loginState.loading}
            >
              Anmelden
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default Login;
