import { FC, ReactNode } from "react";
import BigNumber from "./BigNumber";
import Card from "./Card";
import Headline from "./Headline";
import Subline from "./Subline";

interface HeadlineCardProps {
  headline: string;
  children?: ReactNode;
}

const HeadlineCard: FC<HeadlineCardProps> = (props: HeadlineCardProps) => {
  return (
    <Card>
      <Headline>{props.headline}</Headline>
      {props.children}
    </Card>
  );
};

export default HeadlineCard;
