import Fill from "ol/style/Fill";
import Stroke from "ol/style/Stroke";
import Style from "ol/style/Style";
import Text from "ol/style/Text";
import { IArea } from "../store";

export const generateStyleForFeature = (
  area: IArea,
  hover: boolean = false
) => {
  let fillColor = "rgba(0,255,0,0.1)";
  let strokeColor = "rgba(0,255,0,0.8)";

  let fillColorHover = "rgba(0,255,0, 0.4)";
  let strokeColorHover = "rgba(0,255,0, 1)";

  if (area.status === "blocked") {
    fillColor = "rgba(0,0,0,0.1)";
    strokeColor = "rgba(0,0,0,0.4)";

    fillColorHover = "rgba(0,0,0,0.3)";
    strokeColorHover = "rgba(0,0,0,0.6)";
  } else if (area.status === "completed") {
    fillColor = "rgba(255,0,0,0.1)";
    strokeColor = "rgba(255,0,0,1)";

    fillColorHover = "rgba(255,0,0,0.5)";
    strokeColorHover = "rgba(255,0,0,1)";
  } else if (area.status === "active") {
    fillColor = "rgba(255,255,0,0.1)";
    strokeColor = "rgba(255,255,0,1)";

    fillColorHover = "rgba(255,255,0,0.5)";
    strokeColorHover = "rgba(255,255,0,1)";
  }

  if (hover) {
    return new Style({
      fill: new Fill({
        color: fillColorHover,
      }),
      stroke: new Stroke({
        color: strokeColorHover,
        width: area.status === "active" ? 5 : 2,
      }),
      text: new Text({
        text: area.name,
        font: "30px Noto Sans",
        fill: new Fill({
          color: "rgba(0,0,0,1)",
        }),
        stroke: new Stroke({
          color: fillColorHover,
          width: area.status === "active" ? 5 : 2,
        }),
        overflow: true,
      }),
    });
  }

  return new Style({
    fill: new Fill({
      color: fillColor,
    }),
    stroke: new Stroke({
      color: strokeColor,
      width: area.status === "active" ? 5 : 2,
    }),
  });
};
