import { FC } from "react";
import { storeDefault, StoreProvider, useStore } from "./store";
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import Navigation from "./shared/ui/Navigation";
import Login from "./auth/containers/Login";
import Home from "./home/containers/Home";
import Areas from "./areas/containers/Areas";
import Employees from "./employees/containers/Employees";
import Map from "./map/containers/Map";
import Missions from "./missions/containers/Missions";
import AreaDetail from "./areas/containers/AreaDetail";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MissionDetail from "./missions/containers/MissionDetail";
import AreaEdit from "./areas/containers/AreaEdit";
import EmployeeDetail from "./employees/containers/EmployeeDetail";
import MissionEdit from "./missions/containers/MissionEdit";
import CreateEmployee from "./employees/containers/EmployeeCreate";
import UpdateEmployee from "./employees/containers/EmployeeEdit";

const RequireAuth = ({ children }: { children: JSX.Element }) => {
  const [store] = useStore();
  const location = useLocation();

  if (store.accessToken === undefined) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
};

const App: FC = () => {
  return (
    <>
      <StoreProvider initialValue={storeDefault}>
        <BrowserRouter>
          <Navigation></Navigation>
          <Routes>
            <Route
              path="*"
              element={
                <RequireAuth>
                  <Home />
                </RequireAuth>
              }
            />
            <Route
              path="/map"
              element={
                <RequireAuth>
                  <Map />
                </RequireAuth>
              }
            />
            <Route
              path="/areas"
              element={
                <RequireAuth>
                  <Areas />
                </RequireAuth>
              }
            />
            <Route
              path="/areas/:id"
              element={
                <RequireAuth>
                  <AreaDetail />
                </RequireAuth>
              }
            />
            <Route
              path="/areas/:id/edit"
              element={
                <RequireAuth>
                  <AreaEdit />
                </RequireAuth>
              }
            />
            <Route
              path="/missions"
              element={
                <RequireAuth>
                  <Missions />
                </RequireAuth>
              }
            />
            <Route
              path="/missions/:id"
              element={
                <RequireAuth>
                  <MissionDetail />
                </RequireAuth>
              }
            />
            <Route
              path="/missions/:id/edit"
              element={
                <RequireAuth>
                  <MissionEdit />
                </RequireAuth>
              }
            />
            <Route
              path="/employees"
              element={
                <RequireAuth>
                  <Employees />
                </RequireAuth>
              }
            />
            <Route
              path="/employees/create"
              element={
                <RequireAuth>
                  <CreateEmployee />
                </RequireAuth>
              }
            />
            <Route
              path="/employees/:id"
              element={
                <RequireAuth>
                  <EmployeeDetail />
                </RequireAuth>
              }
            />
            <Route
              path="/employees/:id/edit"
              element={
                <RequireAuth>
                  <UpdateEmployee />
                </RequireAuth>
              }
            />
            <Route path="/login" element={<Login />} />
          </Routes>
        </BrowserRouter>
      </StoreProvider>
      <ToastContainer />
    </>
  );
};

export default App;
