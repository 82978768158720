import { useStore } from "../store";

export const useAuthActions = () => {
  const [, setStore] = useStore();

  const logout = async () => {
    localStorage.removeItem("accessToken");
    setStore((old) => ({
      ...old,
      accessToken: undefined,
      accessTokenPayload: undefined,
    }));
  };

  return { logout };
};
