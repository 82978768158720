import { FC, ReactNode } from "react";

interface SublineProps {
  children: ReactNode;
  className?: string;
}

const Subline: FC<SublineProps> = (props: SublineProps) => {
  return (
    <h5
      className={
        "mb-4 text-secondary" + (props.className ? " " + props.className : "")
      }
    >
      {props.children}
    </h5>
  );
};

export default Subline;
