import { FC, useEffect } from "react";
import { useAreaActions } from "../../actions/areaActions";
import { useOrganizationActions } from "../../actions/organizationActions";
import AreasTable from "../components/AreasTable";
import HeadlineCard from "../../shared/ui/HeadlineCard";
import { useStore } from "../../store";
import FullContainer from "../../shared/ui/FullContainer";

const Areas: FC = () => {
  const [store, _] = useStore();
  const { getAllAreas } = useAreaActions();
  const { getUsersOrganization } = useOrganizationActions();

  useEffect(() => {
    getAllAreas();
    getUsersOrganization();
  }, []);

  if (!store.areas && !store.organization) {
    return <FullContainer>Laden...</FullContainer>;
  }

  return (
    <FullContainer>
      <HeadlineCard headline="Gebiete">
        <AreasTable data={store.areas} />
      </HeadlineCard>
    </FullContainer>
  );
};

export default Areas;
