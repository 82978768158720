import { FC, useState } from "react";
import { IArea, IDocument, IMission, useStore } from "../../store";
import { Col, Dropdown, ListGroup, ListGroupItem, Row } from "react-bootstrap";
import { formatDate, formatDateAndTime } from "../../utils/formatDate";
import EmojiBig from "../../shared/ui/EmojiBig";
import ConfirmDeleteModal, {
  ConfirmDeleteModalState,
} from "../../shared/ui/ConfirmDeleteModal";
import { useMissionActions } from "../../actions/missionActions";
import { useNavigate } from "react-router-dom";
import { List, ListRowElement } from "../../shared/ui/List";
import BackButton from "../../shared/ui/BackButton";
import Headline from "../../shared/ui/Headline";
import Card from "../../shared/ui/Card";
import Button from "../../shared/ui/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFile,
  faPenToSquare,
  faTrash,
  faUpRightFromSquare,
} from "@fortawesome/free-solid-svg-icons";
import DashboardCard from "../../shared/ui/DashboardCard";
import ThreeDotToggle from "../../shared/ui/ThreeDotToggle";
import { roundToDecimals } from "../../utils/roundToDecimels";
import { isAdminOrTeamlead } from "../../utils/isRole";
import { formatHours } from "../../utils/formatHours";
import { getTimeOfDay } from "../../utils/timeOfDay";

interface MissionDetailProps {
  mission: IMission;
  area: IArea;
}

const MissionDetail: FC<MissionDetailProps> = (props: MissionDetailProps) => {
  const [store] = useStore();
  const navigate = useNavigate();
  const [confirmDeleteModalState, setConfirmDeleteModalState] =
    useState<ConfirmDeleteModalState>({
      open: false,
    });
  const { deleteMission } = useMissionActions();

  const navigateToEditMission = (id: string) => {
    navigate("/missions/" + id + "/edit");
  };

  const onDeleteMission = async (id?: string) => {
    if (id) {
      await deleteMission(id);
      setConfirmDeleteModalState({
        open: false,
        itemId: undefined,
      });
      navigate("/missions");
    }
  };

  const listElements: ListRowElement[] = [
    {
      name: "Gebiet",
      value: isAdminOrTeamlead(store.accessTokenPayload) ? (
        <Button
          onClick={() => navigate("/areas/" + props.area.id)}
          variant="secondary"
        >
          {props.area.name}
          <FontAwesomeIcon
            icon={faUpRightFromSquare}
            style={{ marginLeft: 10 }}
          />
        </Button>
      ) : (
        props.area.name
      ),
    },
    {
      name: "Abgeschlossen von",
      value: isAdminOrTeamlead(store.accessTokenPayload) ? (
        <Button
          onClick={() => navigate("/employees/" + props.mission.finisher.id)}
          variant="secondary"
        >
          {props.mission.finisher.firstname +
            " " +
            props.mission.finisher.lastname}
          <FontAwesomeIcon
            icon={faUpRightFromSquare}
            style={{ marginLeft: 10 }}
          />
        </Button>
      ) : (
        props.mission.finisher.firstname + " " + props.mission.finisher.lastname
      ),
    },
    {
      name: "Proben (PRO)",
      value: props.mission.salesSuccess,
    },
    {
      name: "Nicht angetroffen (NA)",
      value: props.mission.salesNotAvailable,
    },
    {
      name: "Kein Bedarf (KB)",
      value: props.mission.salesFailed,
    },
    {
      name: "Vertriebsstart",
      value: formatDateAndTime(props.mission.salesStart),
    },
    {
      name: "Vertriebsende",
      value: formatDateAndTime(props.mission.salesEnd),
    },
    {
      name: "Fahrzeit",
      value: props.mission.drivingTime ? props.mission.drivingTime : "/",
    },
    {
      name: "Stimmung",
      value: <EmojiBig rating={props.mission.rating}></EmojiBig>,
    },
    {
      name: "Kommentar",
      value: props.mission.comment ? props.mission.comment : "/",
    },
    {
      name: "Dokumente",
      value:
        props.mission.documents && props.mission.documents.length !== 0 ? (
          <ListGroup>
            {props.mission.documents.map((document: IDocument) => (
              <ListGroupItem
                action
                href={
                  process.env.REACT_APP_BACKEND_URL +
                  "/document/" +
                  document.id +
                  "/download"
                }
                target="_blank"
                active={false}
              >
                <FontAwesomeIcon icon={faFile} style={{ marginRight: 10 }} />
                {document.name}
                {" (" + formatDateAndTime(document.createdAt) + ")"}
              </ListGroupItem>
            ))}
          </ListGroup>
        ) : (
          "/"
        ),
    },
    {
      name: "Zuletzt geändert am",
      value: formatDateAndTime(props.area.updatedAt),
    },
    {
      name: "Erstellt am",
      value: formatDateAndTime(props.area.createdAt),
    },
  ];

  return (
    <>
      <Row>
        <Col>
          <BackButton />
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <Row>
              <Col>
                <Headline>
                  Einsatz{" "}
                  <span className="fw-light">
                    {formatDate(props.mission.salesStart) +
                      " " +
                      props.mission.finisher.firstname +
                      " " +
                      props.mission.finisher.lastname}
                  </span>
                </Headline>
              </Col>
              {isAdminOrTeamlead(store.accessTokenPayload) && (
                <Col className="col-auto">
                  <Dropdown drop="down">
                    <Dropdown.Toggle as={ThreeDotToggle}></Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        as="button"
                        onClick={() => navigateToEditMission(props.mission.id)}
                      >
                        <FontAwesomeIcon
                          style={{ marginRight: 10 }}
                          icon={faPenToSquare}
                        />
                        Bearbeiten
                      </Dropdown.Item>
                      <Dropdown.Divider />
                      <Dropdown.Item
                        as="button"
                        onClick={() =>
                          setConfirmDeleteModalState({
                            open: true,
                            itemId: props.mission.id,
                          })
                        }
                      >
                        <FontAwesomeIcon
                          style={{ marginRight: 10 }}
                          icon={faTrash}
                        />
                        Löschen
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Col>
              )}
            </Row>
            <List data={listElements}></List>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={4}>
          <DashboardCard
            subline="Vertriebszeit Gesamt"
            headline={formatHours(props.mission.salesDuration)}
          />
        </Col>
        <Col xs={12} md={4}>
          <DashboardCard
            subline="Schnitt"
            headline={String(props.mission.salesSuccessPerHour)}
          />
        </Col>
        <Col xs={12} md={4}>
          <DashboardCard
            subline="Vormittag / Mittag / Nachmittag"
            headline={getTimeOfDay(
              new Date(props.mission.salesStart),
              new Date(props.mission.salesEnd)
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={4}>
          <DashboardCard
            subline="Proben %"
            headline={
              roundToDecimals(props.mission.percentageSalesSuccess * 100, 2) +
              "%"
            }
          />
        </Col>
        <Col xs={12} md={4}>
          <DashboardCard
            subline="Kein Bedarf %"
            headline={
              roundToDecimals(props.mission.percentageSalesFailed * 100, 2) +
              "%"
            }
          />
        </Col>
        <Col xs={12} md={4}>
          <DashboardCard
            subline="Nicht Angetroffen %"
            headline={
              roundToDecimals(
                props.mission.percentageSalesNotAvailable * 100,
                2
              ) + "%"
            }
          />
        </Col>
      </Row>
      <ConfirmDeleteModal
        open={confirmDeleteModalState.open}
        nameInText={"den Einsatz"}
        nameInHeadline={"Einsatz"}
        onSuccess={() => onDeleteMission(confirmDeleteModalState.itemId)}
        onAbort={() =>
          setConfirmDeleteModalState({ open: false, itemId: undefined })
        }
      ></ConfirmDeleteModal>
    </>
  );
};

export default MissionDetail;
