import { FC, useEffect } from "react";
import { useAreaActions } from "../../actions/areaActions";
import { useOrganizationActions } from "../../actions/organizationActions";
import MissionsTable from "../components/MissionsTable";
import HeadlineCard from "../../shared/ui/HeadlineCard";
import { useStore } from "../../store";
import { getActiveUser } from "../../utils/activeUser";
import { isAdminOrTeamlead } from "../../utils/isRole";
import { generateMissionsView, IMissionView } from "../../utils/views";
import FullContainer from "../../shared/ui/FullContainer";

const Missions: FC = () => {
  const [store, _] = useStore();
  const { getAllAreas } = useAreaActions();
  const { getUsersOrganization } = useOrganizationActions();

  useEffect(() => {
    getAllAreas();
    getUsersOrganization();
  }, []);

  if (!store.areas || !store.organization) {
    return <FullContainer>Laden...</FullContainer>;
  }

  const missionViews = isAdminOrTeamlead(store.accessTokenPayload)
    ? generateMissionsView(store.areas)
    : generateMissionsView(store.areas).filter(
        (missionView: IMissionView) =>
          missionView.promoterId === getActiveUser(store)?.id
      );

  return (
    <FullContainer>
      <HeadlineCard headline="Einsätze">
        <MissionsTable data={missionViews}></MissionsTable>
      </HeadlineCard>
    </FullContainer>
  );
};

export default Missions;
