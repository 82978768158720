export const mapStatus = (
  status: "notAssigned" | "active" | "completed" | "blocked"
) => {
  switch (status) {
    case "notAssigned":
      return "Nicht zugewiesen";
    case "active":
      return "In Bearbeitung";
    case "blocked":
      return "Gesperrt";
    case "completed":
      return "Abgeschlossen";
  }
};
