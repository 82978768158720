export const getTimeOfDay = (start: Date, end: Date) => {
  const startHours = start.getHours();
  const endHours = end.getHours();
  const duration = (end.getTime() - start.getTime()) / 1000 / 60 / 60; // Duration in hours

  const morningStart = 6;
  const morningEnd = 12;
  const lunchtimeStart = 12;
  const lunchtimeEnd = 15;

  const morningDuration = Math.max(
    Math.min(endHours, morningEnd) - Math.max(startHours, morningStart),
    0
  );
  const lunchtimeDuration = Math.max(
    Math.min(endHours, lunchtimeEnd) - Math.max(startHours, lunchtimeStart),
    0
  );
  const eveningDuration = duration - morningDuration - lunchtimeDuration;

  if (
    morningDuration >= lunchtimeDuration &&
    morningDuration >= eveningDuration
  ) {
    return "Vormittag";
  } else if (
    lunchtimeDuration >= morningDuration &&
    lunchtimeDuration >= eveningDuration
  ) {
    return "Mittag";
  }
  return "Nachmittag";
};
