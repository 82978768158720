import { FC } from "react";
import "./EmojiRating.css";

interface EmojiRatingProps {
  rating: number | null;
  onChange: (rating: number) => void;
  error: boolean;
}

const EmojiRating: FC<EmojiRatingProps> = (props: EmojiRatingProps) => {
  const className =
    props.error === true
      ? "d-flex flex-row mt-2 border border-danger rounded"
      : "d-flex flex-row mt-2";
  return (
    <div className={className}>
      <img
        className={props.rating !== 1 ? "inactiveImage" : "activeImage"}
        src="https://img.icons8.com/emoji/48/000000/angry-face-emoji--v2.png"
        onClick={() => props.onChange(1)}
      />
      <img
        className={props.rating !== 2 ? "inactiveImage" : "activeImage"}
        src="https://img.icons8.com/fluent/48/000000/sad.png"
        onClick={() => props.onChange(2)}
      />
      <img
        className={props.rating !== 3 ? "inactiveImage" : "activeImage"}
        src="https://img.icons8.com/color/48/000000/happy.png"
        onClick={() => props.onChange(3)}
      />
      <img
        className={props.rating !== 4 ? "inactiveImage" : "activeImage"}
        src="https://img.icons8.com/emoji/48/000000/smiling-face.png"
        onClick={() => props.onChange(4)}
      />
      <img
        className={props.rating !== 5 ? "inactiveImage" : "activeImage"}
        src="https://img.icons8.com/color/48/000000/lol.png"
        onClick={() => props.onChange(5)}
      />
    </div>
  );
};

export default EmojiRating;
