import {
  faEye,
  faPenToSquare,
  faSort,
  faSortDown,
  faSortUp,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import { FC, useState } from "react";
import { Col, Dropdown, Row, Table as BTable } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useMissionActions } from "../../actions/missionActions";
import { useStore } from "../../store";
import { fuzzyFilter } from "../../utils/fuzzyFilter";
import { isAdminOrTeamlead } from "../../utils/isRole";
import { IMissionView } from "../../utils/views";
import ConfirmDeleteModal, {
  ConfirmDeleteModalState,
} from "../../shared/ui/ConfirmDeleteModal";
import EmptyList from "../../shared/ui/EmptyList";
import { DebouncedInput } from "../../shared/ui/DebouncedInput";
import ThreeDotToggle from "../../shared/ui/ThreeDotToggle";

interface MissionsTableProps {
  data: IMissionView[];
}
const columnHelper = createColumnHelper<IMissionView>();

const columns = [
  columnHelper.accessor(
    (row) => row.area.name + " " + row.area.city + " " + row.area.zipCode,
    {
      header: "Gebiet",
      cell: (props) => (
        <div className="d-inline-flex flex-column p-2">
          <span className="fw-bold">{props.row.original.area.name}</span>
          <span>
            {props.row.original.area.city +
              " (" +
              props.row.original.area.zipCode +
              ")"}
          </span>
        </div>
      ),
    }
  ),
  columnHelper.accessor("promoter", {
    header: "Promoter",
  }),
  columnHelper.accessor("salesSuccess", {
    header: "Proben",
  }),
  columnHelper.accessor("salesSuccessPerHour", {
    header: "Schnitt",
  }),
  columnHelper.accessor("salesStartDate", {
    header: "Vertriebsstart",
  }),
  columnHelper.accessor("salesEndDate", {
    header: "Vertriebsende",
  }),
  columnHelper.accessor("createdAt", {
    header: "Hochgeladen am",
  }),
];

const MissionsTable: FC<MissionsTableProps> = (props: MissionsTableProps) => {
  const [store, setStore] = useStore();
  const [sorting, setSorting] = useState<SortingState>([]);
  const [globalFilter, setGlobalFilter] = useState<string>("");
  const { deleteMission } = useMissionActions();
  const navigate = useNavigate();
  const table = useReactTable<IMissionView>({
    data: props.data,
    columns,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    state: {
      sorting,
      globalFilter,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
  });

  const [confirmDeleteModalState, setConfirmDeleteModalState] =
    useState<ConfirmDeleteModalState>({
      open: false,
    });

  const navigateToMission = (id: string) => {
    navigate("/missions/" + id);
  };

  const navigateToEditMission = (id: string) => {
    navigate("/missions/" + id + "/edit");
  };

  const onDeleteMission = async (id?: string) => {
    if (id) {
      await deleteMission(id);
      setConfirmDeleteModalState({
        open: false,
        itemId: undefined,
      });
    }
  };

  if (props.data.length === 0) {
    return <EmptyList name="Einsätze" />;
  }

  return (
    <>
      <Row>
        <Col xs={12} lg={4}>
          <DebouncedInput
            value={globalFilter ?? ""}
            onChange={(value: string | number) =>
              setGlobalFilter(String(value))
            }
            placeholder="Suchen ..."
          />
        </Col>
      </Row>

      <BTable hover>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <th key={header.id} colSpan={header.colSpan}>
                    {header.isPlaceholder ? null : (
                      <div
                        {...{
                          role: header.column.getCanSort() ? "button" : "",
                          onClick: header.column.getToggleSortingHandler(),
                        }}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {{
                          asc: (
                            <FontAwesomeIcon icon={faSortUp}></FontAwesomeIcon>
                          ),
                          desc: (
                            <FontAwesomeIcon
                              icon={faSortDown}
                            ></FontAwesomeIcon>
                          ),
                        }[header.column.getIsSorted() as string] ?? (
                          <FontAwesomeIcon
                            icon={faSort}
                            className="text-muted"
                          ></FontAwesomeIcon>
                        )}
                      </div>
                    )}
                  </th>
                );
              })}

              {isAdminOrTeamlead(store.accessTokenPayload) && (
                <th key="actions"></th>
              )}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id} className={"cursor-hover"}>
              {row.getVisibleCells().map((cell) => (
                <td
                  key={cell.id}
                  onClick={() => navigateToMission(row.original.id)}
                  className="align-middle"
                  style={{ height: 40 }}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}

              {isAdminOrTeamlead(store.accessTokenPayload) && (
                <td key={row.id + "-action"} className="align-middle">
                  <Dropdown drop="down">
                    <Dropdown.Toggle as={ThreeDotToggle}></Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        as="button"
                        onClick={() => navigateToMission(row.original.id)}
                      >
                        <FontAwesomeIcon
                          style={{ marginRight: 10 }}
                          icon={faEye}
                        />
                        Anzeigen
                      </Dropdown.Item>
                      <Dropdown.Item
                        as="button"
                        onClick={() => navigateToEditMission(row.original.id)}
                      >
                        <FontAwesomeIcon
                          style={{ marginRight: 10 }}
                          icon={faPenToSquare}
                        />
                        Bearbeiten
                      </Dropdown.Item>
                      <Dropdown.Divider />
                      <Dropdown.Item
                        as="button"
                        onClick={() =>
                          setConfirmDeleteModalState({
                            open: true,
                            itemId: row.original.id,
                          })
                        }
                      >
                        <FontAwesomeIcon
                          style={{ marginRight: 10 }}
                          icon={faTrash}
                        />
                        Löschen
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </BTable>
      <ConfirmDeleteModal
        open={confirmDeleteModalState.open}
        nameInText={"den Einsatz"}
        nameInHeadline={"Einsatz"}
        onSuccess={() => onDeleteMission(confirmDeleteModalState.itemId)}
        onAbort={() =>
          setConfirmDeleteModalState({ open: false, itemId: undefined })
        }
      ></ConfirmDeleteModal>
    </>
  );
};

export default MissionsTable;
