import axios, { AxiosError, AxiosInstance } from "axios";

interface ILogicValidationErrorResponse {
  statusCode: number;
  message: string;
  error: string;
}

interface IValidationErrorResponse {
  statusCode: number;
  message: string[];
  error: string;
}

type FieldError = {
  fieldName: string;
  errorMessage: string;
};

export class LogicValidationError {
  private readonly errorMessage: string;
  private readonly code: number;
  private readonly error: string;

  constructor(code: number, error: string, errorMessage: string) {
    this.code = code;
    this.error = error;
    this.errorMessage = errorMessage;
  }

  // this is the actual mapping logic of API errors to field errors that can be used by forms
  // this should probably live somewhere else, but for now it's ok
  private mapErrorToFieldErrors(error: string) {
    const fieldErrors: FieldError[] = [];
    switch (error) {
      case "USER_EXISTS":
        fieldErrors.push({
          fieldName: "username",
          errorMessage: "Dieser Nutzername wird bereits verwendet.",
        });
        break;
      case "AREA_EXISTS":
        fieldErrors.push({
          fieldName: "name",
          errorMessage: "Dieser Gebietsname wird bereits verwendet.",
        });
        break;
    }
    return fieldErrors;
  }

  // in some contexts a fieldMapping is required to assign specific errors to specific fields
  getFieldErrors(fieldMapping?: (fieldName: string) => string) {
    const fieldErrors = this.mapErrorToFieldErrors(this.error);
    if (fieldMapping) {
      for (let fieldError of fieldErrors) {
        fieldError.fieldName =
          fieldMapping(fieldError.fieldName) || fieldError.fieldName;
      }
    }
    return fieldErrors;
  }
}

export class ValidationError {
  private readonly errorMessages: string[];
  private readonly code: number;
  private readonly error: string;

  constructor(code: number, error: string, errorMessages: string[]) {
    this.code = code;
    this.error = error;
    this.errorMessages = errorMessages;
  }

  // this is the actual mapping logic of API errors to field errors that can be used by forms
  // this should probably live somewhere else, but for now it's ok
  private mapErrorMessageToFieldErrors(errorMessages: string[]) {
    const fieldErrors: FieldError[] = [];
    for (let errorMessage of errorMessages) {
      const splittedErrorMessage = errorMessage.split(" ");
      const [fieldName, ...errorMessageArray] = splittedErrorMessage;
      const error = errorMessageArray.join(" ");
      switch (error) {
        case "must be an IBAN":
          fieldErrors.push({
            fieldName: fieldName,
            errorMessage: "Bitte eine valide IBAN eingeben.",
          });
          break;
        case "must be a BIC or SWIFT code":
          fieldErrors.push({
            fieldName: fieldName,
            errorMessage: "Bitte eine valide BIC eingeben.",
          });
          break;
        case "should not be empty":
          fieldErrors.push({
            fieldName: fieldName,
            errorMessage: "Bitte das Feld ausfüllen.",
          });
          break;
        case "must be a valid enum value":
          fieldErrors.push({
            fieldName: fieldName,
            errorMessage: "Bitte einen validen Wert auswählen.",
          });
          break;
        case "must be a valid ISO 8601 date string":
          fieldErrors.push({
            fieldName: fieldName,
            errorMessage: "Bitte ein valides Datum eingeben.",
          });
          break;
        case "must be a number conforming to the specified constraints":
          fieldErrors.push({
            fieldName: fieldName,
            errorMessage: "Bitte eine Nummer eingeben.",
          });
          break;
        case "must be an array":
          fieldErrors.push({
            fieldName: fieldName,
            errorMessage: "Bitte mehrere Werte eingeben.",
          });
          break;
      }
    }
    return fieldErrors;
  }

  getFieldErrors(fieldMapping?: (fieldName: string) => string): FieldError[] {
    const fieldErrors = this.mapErrorMessageToFieldErrors(this.errorMessages);
    if (fieldMapping) {
      for (let fieldError of fieldErrors) {
        fieldError.fieldName =
          fieldMapping(fieldError.fieldName) || fieldError.fieldName;
      }
    }
    return fieldErrors;
  }
}

export class InternalServerError {
  private readonly errorMessage: string;
  private readonly code: number;
  private readonly error: string;

  constructor(code: number, error: string, errorMessage: string) {
    this.code = code;
    this.error = error;
    this.errorMessage = errorMessage;
  }
}

export class Api {
  private api: AxiosInstance;
  private unauthorizedHandler: (url: string) => void;

  public constructor(
    baseUrl: string,
    onUnauthorized: (url: string) => void,
    accessToken?: string
  ) {
    const headers = {
      "Content-Type": "application/json",
      Authorization: accessToken ? "Bearer " + accessToken : "",
    };

    this.api = axios.create({
      baseURL: baseUrl,
      headers: headers,
    });

    this.unauthorizedHandler = onUnauthorized;
  }

  private processError = (url: string, error: AxiosError) => {
    console.log("Start");
    if (error.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      console.log("err-data", error.response.data);
      console.log("err-status", error.response.status);
      console.log("err-headers", error.response.headers);

      if (
        error.response.status === 401 ||
        error.response.status === 403 ||
        error.response.status === 407
      ) {
        this.unauthorizedHandler(url);
      } else if (error.response.status === 400) {
        const apiError = error.response.data as IValidationErrorResponse;
        throw new ValidationError(400, apiError.error, apiError.message);
      } else if (
        error.response.status === 404 ||
        error.response.status === 409 ||
        error.response.status === 400 ||
        error.response.status === 422
      ) {
        const apiError = error.response.data as ILogicValidationErrorResponse;
        throw new LogicValidationError(
          apiError.statusCode,
          apiError.error,
          apiError.message
        );
      } else {
        throw new InternalServerError(
          500,
          "INTERNAL_SERVER_ERROR",
          "Response received, but error."
        );
      }
    } else if (error.request) {
      /*
       * The request was made but no response was received, `error.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      console.error("Request error", error.request);
      throw new InternalServerError(
        500,
        "INTERNAL_SERVER_ERROR",
        "No response received."
      );
    } else {
      // Something happened in setting up the request and triggered an Error
      console.error("Error", error);
      throw new InternalServerError(
        500,
        "INTERNAL_SERVER_ERROR",
        "Setup of request unsuccessful."
      );
    }
  };

  public get = async (url: string) => {
    try {
      const response = await this.api.get(url);
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        this.processError(url, error);
      } else {
        throw error;
      }
    }
  };

  public post = async (url: string, data: unknown) => {
    try {
      const response = await this.api.post(url, data);
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        this.processError(url, error);
      } else {
        throw error;
      }
    }
  };

  public remove = async (url: string) => {
    try {
      const response = await this.api.delete(url);
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        this.processError(url, error);
      } else {
        throw error;
      }
    }
  };
}
