import { FC } from "react";
import { Badge } from "react-bootstrap";

interface AreaStatusBadgeProps {
  status: "notAssigned" | "active" | "completed" | "blocked";
}

export const AreaStatusBadge: FC<AreaStatusBadgeProps> = (
  props: AreaStatusBadgeProps
) => {
  if (props.status === "blocked") {
    return (
      <Badge
        bg="secondary"
        className="bg-secondary bg-gradient rounded-pill mb-2"
      >
        Gesperrt
      </Badge>
    );
  }

  if (props.status === "notAssigned") {
    return (
      <Badge bg="success" className="bg-success bg-gradient rounded-pill mb-2">
        Nicht zugewiesen
      </Badge>
    );
  }

  if (props.status === "active") {
    return (
      <Badge bg="warning" className="bg-warning bg-gradient rounded-pill mb-2">
        In Bearbeitung
      </Badge>
    );
  }

  return (
    <Badge bg="danger" className="bg-danger bg-gradient rounded-pill mb-2">
      Abgeschlossen
    </Badge>
  );
};
