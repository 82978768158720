import { FC } from "react";
import BigNumber from "./BigNumber";
import Card from "./Card";
import Subline from "./Subline";

interface DashboardCardProps {
  headline: string;
  subline: string;
}

const DashboardCard: FC<DashboardCardProps> = (props: DashboardCardProps) => {
  return (
    <Card>
      <Subline>{props.subline}</Subline>
      <BigNumber>{props.headline}</BigNumber>
    </Card>
  );
};

export default DashboardCard;
